import { useMutation, useQueryClient } from "@tanstack/react-query";

import { BaseError } from "@/domain/common/errors";
import { ShiftVariantOverride } from "@/domain/shifts";
import { useSelectedStationIds } from "@/view/pages/line-id/use-selected-station-ids";
import { useApiClient } from "@/view/providers/api-client-provider";

import { useSelectedDateRange } from "../line-id/use-selected-date-range";
import { useSelectedLine } from "../line-id/use-selected-line";
import { useSelectedProductIds } from "../line-id/use-selected-product-ids";
import { useSelectedShiftIds } from "../line-id/use-selected-shift-ids";
import { useSelectedTagIds } from "../line-id/use-selected-tag-ids";
import { shiftWithVariantsQueryKey } from "../line-id-settings-shifts/use-shift-variants";
import { getLineStatisticsKey } from "./line-statistics-provider";

export function useCreateTargetsOverrideMutation() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const line = useSelectedLine();
  const shiftIds = useSelectedShiftIds();
  const tagIds = useSelectedTagIds();
  const dateRange = useSelectedDateRange();
  const productIds = useSelectedProductIds();
  const stationIds = useSelectedStationIds();
  return useMutation<void, BaseError, ShiftVariantOverride>({
    mutationFn: async (params) => {
      return apiClient.createShiftVariantOverride(params);
    },
    onSuccess(_, params) {
      const [lineStatistics] = getLineStatisticsKey(
        line.id,
        dateRange,
        shiftIds,
        tagIds,
        productIds,
        stationIds
      );
      // Override is successful so we need to update line statistics
      queryClient.invalidateQueries({ queryKey: [lineStatistics] });
      // and the shift with variants query
      queryClient.invalidateQueries({
        queryKey: shiftWithVariantsQueryKey(line.id, params.date),
      });
    },
  });
}
