import { cn } from "@/view/utils";

type LegendItem = {
  value: string;
  color: string;
  name: string;
};

export function ChartLegend({
  items,
  hiddenLegends,
  onLegendClick,
}: {
  items: Array<LegendItem>;
  hiddenLegends: Array<string>;
  onLegendClick: (legend: string) => void;
}) {
  return (
    <ul className="flex gap-2">
      {items.map(({ value, color, name }) => (
        <li key={value}>
          <button
            type="button"
            className={cn(
              "flex gap-2 items-center px-2 py-1",
              "border border-brand-gray-2 rounded-md",
              "text-sm text-brand-black bg-brand-gray-1",
              {
                "text-brand-gray-3 bg-brand-white":
                  hiddenLegends.includes(value),
              }
            )}
            onClick={() => onLegendClick(value)}
          >
            <div
              className="w-2 h-2 rounded-full"
              style={{
                backgroundColor: color,
              }}
            />
            {name}
          </button>
        </li>
      ))}
    </ul>
  );
}
