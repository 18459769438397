import { useLingui } from "@lingui/react";
import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { StationId } from "@/domain/station";
import bgPatternImg from "@/view/assets/bg-pattern.png";
import searchCloudImg from "@/view/assets/search-cloud.png";
import { CardContent, TabsContent } from "@/view/components";
import { LineCycleTimeByStationChart } from "@/view/pages/line-id-reporting/line-cycle-time-by-station-chart";
import { useElementSize } from "@/view/providers/use-element-size";
import { paths } from "@/view/routes";

import {
  encodeDateRangeToSearchParams,
  useSelectedDateRange,
} from "../line-id/use-selected-date-range";
import { useSelectedLine } from "../line-id/use-selected-line";
import {
  encodeShiftIdsToSearchParams,
  useSelectedShiftIds,
} from "../line-id/use-selected-shift-ids";
import { LineActivityByStationChart } from "./line-activity-by-station-chart";
import { LineOutputByStationChart } from "./line-output-by-station-chart";
import { LineOutputByTimeChart } from "./line-output-by-time-chart";

export function LineReportingChartsLoading() {
  const shimmers = useMemo(() => {
    return Array.from({ length: 12 }).map((_, i) => ({
      key: i,
      height: `${Math.floor(Math.random() * 300) + 100}px`,
    }));
  }, []);
  return (
    <CardContent className="grow flex items-end justify-center gap-12 pt-24">
      {shimmers.map((it) => (
        <div
          key={it.key}
          className="w-full max-w-[4rem] animate-pulse bg-brand-gray-2 rounded-md"
          style={{ height: it.height }}
        />
      ))}
    </CardContent>
  );
}

/**
 * @todo replace with ChartErrorStatus component
 */
export function ChartsErrorStatus({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) {
  const { i18n } = useLingui();
  return (
    <CardContent
      className="grow flex items-center justify-center gap-12 pt-24 min-h-[540px] bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${bgPatternImg})` }}
    >
      <div className="text-center max-w-md">
        <img src={searchCloudImg} alt="" className="mx-auto mb-8" />
        <h3 className="text-xl font-bold text-brand-black">
          {title ?? i18n.t("lineOverviewChartErrorTitle")}
        </h3>
        <p>{description ?? i18n.t("lineOverviewChartErrorDescription")}</p>
      </div>
    </CardContent>
  );
}

export function LineStatistics() {
  const { ref, width } = useElementSize();
  const navigate = useNavigate();
  const shiftIds = useSelectedShiftIds();
  const line = useSelectedLine();
  const dateRange = useSelectedDateRange();

  const heightMin = 480;
  const heightMax = 580;
  const height = Math.max(heightMin, Math.min(heightMax, (width * 8) / 16));

  function onStationSelect(stationId: StationId) {
    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, dateRange);
    navigate(
      generatePath(paths.lineReportingStationDetailsPath, {
        lineId: line.id,
        stationId,
      }) + `?${searchParams}`
    );
  }

  return (
    <CardContent ref={(el) => (ref.current = el)} className="grow h-full p-0">
      {width > 0 && (
        <>
          <TabsContent value="output">
            <LineOutputByStationChart
              height={height}
              onStationSelect={onStationSelect}
            />
          </TabsContent>
          <TabsContent value="avg_cycle_time">
            <LineCycleTimeByStationChart
              height={height}
              onStationSelect={onStationSelect}
            />
          </TabsContent>
          <TabsContent value="activity">
            <LineActivityByStationChart
              height={height}
              onStationSelect={onStationSelect}
            />
          </TabsContent>
          <TabsContent value="output_by_time">
            <LineOutputByTimeChart width={width} height={height} />
          </TabsContent>
        </>
      )}
    </CardContent>
  );
}
