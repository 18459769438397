import { I18n } from "@lingui/core";

import { StationId } from "@/domain/station";
import { TagId, TagStore } from "@/domain/tag";

import { ChartType } from "./chart-type-select";

export function getLegendName(
  key: string,
  chartType: ChartType,
  tagsStore: TagStore,
  i18n: I18n
) {
  if (
    (chartType === "compared" || chartType === "single") &&
    !key.endsWith("_value")
  ) {
    // TODO: clean up this mess
    if (key === "unknown_variant") {
      return i18n.t("Default variant");
    }
    return tagsStore.tagsById[key as TagId]?.name ?? key;
  }

  switch (key) {
    case "count_value":
    case "unknown_variant":
      return i18n.t("Cycle count");
    case "time_value":
      return i18n.t("Average cycle time");
    case "count_intersection":
      return i18n.t("Intersection");
    case "count_difference":
      return i18n.t("Difference");
    default:
      return key;
  }
}

export function getColor(
  key: string,
  chartType: ChartType,
  tagsStore: TagStore
) {
  if (chartType === "compared" || chartType === "single") {
    return tagsStore.tagsById[key as TagId]?.color ?? "#148CFF";
  }

  switch (key) {
    case "count_difference":
      return "#CFDBE7";
    case "count_value":
    case "time_value":
    case "count_intersection":
    default:
      return "#148CFF";
  }
}

export function getOutputKeysForChartType(
  chartType: ChartType,
  selectedTagIds: Array<TagId>,
  selectedProductIds: Array<TagId>
) {
  if (chartType === "combined") {
    return ["count_intersection", "count_difference"];
  } else if (chartType === "compared") {
    return ["count_value", ...selectedTagIds];
  } else {
    return getKeysForChart(selectedProductIds);
  }
}

export function getCycleTimeKeysForChartType(
  chartType: ChartType,
  selectedTagIds: Array<TagId>,
  selectedProductIds: Array<TagId>
) {
  if (chartType === "compared") {
    return ["time_value", ...selectedTagIds];
  }

  return getKeysForChart(selectedProductIds);
}

export function getKeysForChart(selectedProductIds: Array<TagId>) {
  return ["unknown_variant", ...selectedProductIds];
}

type ChartData<T extends string> = {
  stationId: StationId;
} & Record<T, { key: string; value: number }[]>;

export function getValueForChartType<T extends string>(
  chartType: T,
  dataByStation: ChartData<T>,
  hiddenLegends: Array<string>
): { [key: string]: number | StationId } {
  return {
    stationId: dataByStation.stationId,
    ...Object.fromEntries(
      dataByStation[chartType]
        .filter((it) => !hiddenLegends.includes(it.key))
        .map((it) => [it.key, it.value])
    ),
  };
}
