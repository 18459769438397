import { z } from "zod";

import { FactoryId, factorySchema } from "./factory";
import { LineId, lineSchema } from "./line";
import { shiftSchema } from "./shifts";
import { Station, StationId, stationSchema } from "./station";

const lineTypeSchema = z.enum(["manual", "machine", "mixed"]);
export type LineType = z.infer<typeof lineTypeSchema>;
const lineWithStationsSchema = lineSchema.extend({
  stations: z.array(stationSchema),
  shifts: z.array(shiftSchema),
  type: lineTypeSchema,
});
const factoryWithLinesSchema = factorySchema.extend({
  lines: z.array(lineWithStationsSchema),
});
const operationalLevelsSchema = z.array(factoryWithLinesSchema);

type FactoryWithLines = z.infer<typeof factoryWithLinesSchema>;
export type LineWithStations = z.infer<typeof lineWithStationsSchema>;
export type OperationalLevels = z.infer<typeof operationalLevelsSchema>;

/**
 * Converts an array of operational levels into a set of lookup tables indexed by IDs.
 * @param operationalLevels - The array of operational levels.
 * @returns An object containing lookup tables for factories, lines, and stations.
 */
export function operatinalLevelsByIds(operationalLevels: OperationalLevels) {
  const factoriesByFactoryId: Record<FactoryId, FactoryWithLines> = {};
  const linesByLineId: Record<LineId, LineWithStations> = {};
  const stationsByStationId: Record<StationId, Station> = {};

  for (const factory of operationalLevels) {
    factoriesByFactoryId[factory.id] = factory;
    for (const line of factory.lines) {
      linesByLineId[line.id] = line;
      for (const station of line.stations) {
        stationsByStationId[station.id] = station;
      }
    }
  }

  return { factoriesByFactoryId, linesByLineId, stationsByStationId };
}

/**
 * Returns the first line from the first factory.
 * @param operationalLevels - The operational levels.
 * @returns The default line.
 */
export function getDefaultLine(
  operationalLevels: OperationalLevels | undefined
) {
  return (operationalLevels ?? []).at(0)?.lines.at(0);
}

/**
 * Returns an array of station IDs in the order specified by the order they appear in the `line`.
 * If `stationIds` is undefined, returns all station IDs from the `line`.
 *
 * @param line - The line object containing the stations in a particular order.
 * @param stationIds - An optional array of station IDs to filter.
 * @returns An array of station IDs in the specified order.
 */
export function getOrderedStationIds(
  line: LineWithStations,
  stationIds: Array<string> | undefined
) {
  const validStationIds = line.stations.map((station) => station.id);
  return validStationIds.filter((stationId) =>
    stationIds ? stationIds.includes(stationId) : true
  );
}

/**
 * Returns the default output station for a given line.
 * If no output station is found, the last station in the line is returned.
 *
 * @param line - The line with stations.
 * @returns The default output station.
 */
export function getDefaultOutputStation(line: LineWithStations) {
  return (
    line.stations.find((station) => station.isOutput) ?? line.stations.at(-1)
  );
}
