import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQuery } from "@tanstack/react-query";
import { fetchAuthSession } from "aws-amplify/auth";

export function useUserGroups() {
  const { user } = useAuthenticator();
  const sessionQuery = useQuery({
    queryKey: ["authSession", user?.userId ?? ""],
    queryFn: () => fetchAuthSession(),
    enabled: !!user,
  });

  if (!sessionQuery.data || !sessionQuery.data.tokens) {
    return null;
  }

  return sessionQuery.data.tokens.accessToken.payload[
    "cognito:groups"
  ] as Array<string>;
}
