import { useSuspenseQuery } from "@tanstack/react-query";
import { createContext, PropsWithChildren, useContext } from "react";

import { DateRangeFilter } from "@/domain/common/time-filter";
import { LineId } from "@/domain/line";
import { ShiftId } from "@/domain/shifts";
import { StationId } from "@/domain/station";
import { LineOverallStatistics } from "@/domain/statistics";
import { TagId } from "@/domain/tag";
import { useSelectedStationIds } from "@/view/pages/line-id/use-selected-station-ids";
import { useApiClient } from "@/view/providers/api-client-provider";

import { useSelectedDateRange } from "../line-id/use-selected-date-range";
import { useSelectedLine } from "../line-id/use-selected-line";
import { useSelectedProductIds } from "../line-id/use-selected-product-ids";
import { useSelectedShiftIds } from "../line-id/use-selected-shift-ids";
import { useSelectedTagIds } from "../line-id/use-selected-tag-ids";

const LineStatisticsContext = createContext<LineOverallStatistics | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useLineStatistics() {
  const lineStatistics = useContext(LineStatisticsContext);
  if (!lineStatistics) {
    throw new Error(
      "useLineStatistics must be used within a LineStatisticsProvider"
    );
  }
  return lineStatistics;
}

// eslint-disable-next-line react-refresh/only-export-components
export function getLineStatisticsKey(
  lineId: LineId,
  dateRange: DateRangeFilter,
  shiftIds: Array<ShiftId>,
  tagIds: Array<TagId>,
  productIds: Array<TagId>,
  stationIds: Array<StationId>
) {
  return [
    "line-statistics",
    lineId,
    dateRange,
    shiftIds,
    tagIds,
    productIds,
    stationIds,
  ];
}

// eslint-disable-next-line react-refresh/only-export-components
export function useLineStatisticsParams(): [
  ReturnType<typeof useSelectedLine>,
  ReturnType<typeof useSelectedShiftIds>,
  ReturnType<typeof useSelectedTagIds>,
  ReturnType<typeof useSelectedDateRange>,
  ReturnType<typeof useSelectedProductIds>,
  ReturnType<typeof useSelectedStationIds>,
] {
  const line = useSelectedLine();
  const shiftIds = useSelectedShiftIds();
  const tagIds = useSelectedTagIds();
  const dateRange = useSelectedDateRange();
  const productIds = useSelectedProductIds();
  const stationIds = useSelectedStationIds();
  return [line, shiftIds, tagIds, dateRange, productIds, stationIds];
}

/**
 * Provides line statistics down the component tree.
 */
export function LineStatisticsProvider({ children }: PropsWithChildren) {
  const apiClient = useApiClient();
  const [line, shiftIds, tagIds, dateRange, productIds, stationIds] =
    useLineStatisticsParams();
  const query = useSuspenseQuery({
    queryKey: getLineStatisticsKey(
      line.id,
      dateRange,
      shiftIds,
      tagIds,
      productIds,
      stationIds
    ),
    queryFn: ({ signal }) => {
      return apiClient.getLineOverallStatistics(
        {
          lineId: line.id,
          dateRange,
          shiftIds,
          tagIds,
          productIds,
          stationIds,
        },
        { signal }
      );
    },
    staleTime: Infinity,
    refetchInterval: 1000 * 60 * 30, // Half an hour
    refetchIntervalInBackground: true,
  });

  return (
    <LineStatisticsContext.Provider value={query.data}>
      {children}
    </LineStatisticsContext.Provider>
  );
}
