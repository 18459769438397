import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  type Duration,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
} from "date-fns";
import { z } from "zod";

import { dateToTrendDataKey } from "./common/metrics";
import {
  DateRangeFilter,
  dateRangeSchema,
  timeRangeSchema,
} from "./common/time-filter";
import { factoryIdSchema } from "./factory";
import { lineIdSchema } from "./line";
import { Shift, shiftIdSchema, shiftSchema } from "./shifts";
import { stationIdSchema } from "./station";
import { tagIdSchema } from "./tag";

const timeGranularity = z.union([
  z.literal("hour"),
  z.literal("day"),
  z.literal("week"),
  z.literal("month"),
  z.literal("quarter"),
]);

const valueTargetSchema = z.object({
  value: z.number().nonnegative(),
  target: z.number().nonnegative(),
});

const cycleCountByTimeSchema = z.object({
  datetime: z.date(),
  aggregationTarget: z.number().nonnegative(),
  aggregationCount: z.number().nonnegative(),
});

const stationCycleCountByTimeSchema = z.object({
  date: z.date(),
  accTarget: z.number().nonnegative(),
  target: z.number().nonnegative(),
  accValue: z.number().nonnegative(),
  value: z.number().nonnegative(),
});

export const lineAccumulatedByTimeStatisticsSchema = z.object({
  timeGranularity: timeGranularity,
  data: z.array(cycleCountByTimeSchema),
  workingHours: z.array(z.tuple([z.date(), z.date()])).nullable(),
});
export type LineAccumulatedByTimeStatistics = z.infer<
  typeof lineAccumulatedByTimeStatisticsSchema
>;

const keyValueSchema = z.object({
  key: z.string(),
  value: z.number().nonnegative(),
});

const stationCycleDataSchema = z.object({
  stationId: stationIdSchema,
  single: z.array(keyValueSchema),
  combined: z.array(keyValueSchema),
  compared: z.array(keyValueSchema),
});
export const cycleCountByStationSchema =
  stationCycleDataSchema.brand<"CycleCountByStation">();

const cycleMeanTimeByTimeSchema = z.object({
  date: z.date(),
  value: z.number().nonnegative(),
});

export const cycleVarianceKeySchema = z.custom<`${number}__${number}`>(
  (val) => {
    return typeof val === "string" ? /^\d+__\d+$/.test(val) : false;
  }
);

const cycleVarianceByTimeSchema = z.object({
  range: z.tuple([z.number().nonnegative(), z.number().nonnegative()]),
  value: z.number().nonnegative(),
});

const stationCycleTimesSchema = z.object({
  stationId: stationIdSchema,
  single: z.array(keyValueSchema),
  compared: z.array(keyValueSchema),
});
export const lineAverageCycleTimeByStationSchema = z.object({
  taktTime: z.number().nonnegative(),
  avgCycleTimeByStation: z.record(stationIdSchema, stationCycleTimesSchema),
});
export type StationCycleTimes = z.infer<typeof stationCycleTimesSchema>;
export type LineAverageCycleTimeByStation = z.infer<
  typeof lineAverageCycleTimeByStationSchema
>;

const lineStatisticsFiltersSchema = z.object({
  lineId: lineIdSchema,
  dateRange: dateRangeSchema,
  shiftIds: z.array(shiftIdSchema),
  tagIds: z.array(tagIdSchema),
  productIds: z.array(tagIdSchema).optional(),
});

const lineOverallStatisticsFiltersSchema = z.object({
  lineId: lineIdSchema,
  dateRange: dateRangeSchema,
  shiftIds: z.array(shiftIdSchema),
  tagIds: z.array(tagIdSchema),
  productIds: z.array(tagIdSchema).optional(),
  stationIds: z.array(stationIdSchema).optional(),
});
export type GetLineOverallStatisticsFilters = z.infer<
  typeof lineOverallStatisticsFiltersSchema
>;
export const lineOverallStatisticsSchema = z.object({
  output: valueTargetSchema.default({ value: 0, target: 0 }),
  meanCycleTime: valueTargetSchema.default({ value: 0, target: 0 }),
  activeTimePercentage: z.number().nonnegative().min(0).max(100).default(0),
});
export type LineOverallStatistics = z.infer<typeof lineOverallStatisticsSchema>;

const linesWithMetricsFiltersSchema = z.object({
  factoryId: factoryIdSchema,
  dateRange: dateRangeSchema,
  timeRange: timeRangeSchema,
});

export const stationStatisticsSchema = z.object({
  timeGranularity: timeGranularity,
  cycleCount: z.number().nonnegative(),
  cycleMedianTime: z.number().nonnegative(),
  cycleMeanTime: z.number().nonnegative(),
  cycleTargetTime: z.number().nonnegative(),
  cycleTimeVariance: z.number().nonnegative(),
  cycleVarianceByTime: z.record(
    cycleVarianceKeySchema,
    cycleVarianceByTimeSchema
  ),
  cycleCountByTime: z.array(stationCycleCountByTimeSchema),
  cycleMeanTimeByTime: z.record(z.string(), cycleMeanTimeByTimeSchema),
});

const stationStatisticsFiltersSchema = z.object({
  stationId: stationIdSchema,
  dateRange: dateRangeSchema,
  shifts: z.array(shiftSchema),
});

const stationsWithMetricsFiltersSchema = z.object({
  lineId: lineIdSchema,
  dateRange: dateRangeSchema,
  timeRange: timeRangeSchema,
});

export const lineOutputPerStationStatisticsSchema = z.object({
  data: z.array(cycleCountByStationSchema),
});

export const activityByProductSchema = z.object({
  productId: tagIdSchema,
  data: z.array(keyValueSchema),
});

export const lineActivityPerStationStatisticsSchema = z.record(
  stationIdSchema,
  z.array(activityByProductSchema)
);

export type TimeGranularity = z.infer<typeof timeGranularity>;
export type CycleVarianceKeySchema = z.infer<typeof cycleVarianceKeySchema>;
type CycleCountByTime = z.infer<typeof stationCycleCountByTimeSchema>;
export type CycleCountByStation = z.infer<typeof cycleCountByStationSchema>;

export type LineStatisticsFilters = z.infer<typeof lineStatisticsFiltersSchema>;
export type GetLineAccumulatedByTimeStatisticsFilters = z.infer<
  typeof lineStatisticsFiltersSchema
>;
export type GetLineAverageCycleTimeByStationFilters = z.infer<
  typeof lineStatisticsFiltersSchema
>;
export type GetLineOutputPerStationStatisticsFilters = z.infer<
  typeof lineStatisticsFiltersSchema
>;
export type LineOutputPerStationStatistics = z.infer<
  typeof lineOutputPerStationStatisticsSchema
>;
export type LinesWithMetricsFilters = z.infer<
  typeof linesWithMetricsFiltersSchema
>;
export type GetLineActivityPerStationStatisticsFilters = z.infer<
  typeof lineStatisticsFiltersSchema
>;
export type LineActivityPerStationStatistics = z.infer<
  typeof lineActivityPerStationStatisticsSchema
>;
export type CycleVarianceByTime = z.infer<typeof cycleVarianceByTimeSchema>;
export type CycleMeanTimeByTime = z.infer<typeof cycleMeanTimeByTimeSchema>;
export type StationStatistics = z.infer<typeof stationStatisticsSchema>;
export type StationStatisticsFilters = z.infer<
  typeof stationStatisticsFiltersSchema
>;
export type StationsWithMetricsFilters = z.infer<
  typeof stationsWithMetricsFiltersSchema
>;

export function buildTimeline(
  dateRange: DateRangeFilter,
  shifts: Array<Shift>,
  timeGranularity: TimeGranularity
) {
  function diffSpecifier() {
    switch (timeGranularity) {
      case "hour":
      case "day":
        return differenceInDays;
      case "week":
        return differenceInWeeks;
      case "month":
        return differenceInMonths;
      case "quarter":
        return differenceInQuarters;
    }
  }

  function startDaySpecifier() {
    switch (timeGranularity) {
      case "hour":
      case "day":
        return startOfDay;
      case "week":
        return startOfWeek;
      case "month":
        return startOfMonth;
      case "quarter":
        return startOfQuarter;
    }
  }

  function durationSpecifier(i: number): Duration {
    switch (timeGranularity) {
      case "hour":
      case "day":
        return { days: i };
      case "week":
        return { weeks: i };
      case "month":
        return { months: i };
      case "quarter":
        return { months: i * 3 };
    }
  }

  const timeline: Array<Date> = [];
  const startDay = dateRange.values.start;
  const endDay = dateRange.values.end;
  const diffFunc = diffSpecifier();
  const startDayFunc = startDaySpecifier();
  const diff = Math.abs(diffFunc(endDay, startDay)) + 1;
  const date = startDayFunc(startDay, { weekStartsOn: 1 });

  const timeRanges = shifts.map(({ start, end }) => {
    const timeStart = startOfHour(start);
    let timeEnd = end;
    if (startOfHour(end) < end) {
      timeEnd = add(startOfHour(end), { hours: 1 });
    }
    if (timeEnd < timeStart) {
      timeEnd = add(timeEnd, { days: 1 });
    }
    const diffHours = Math.abs(differenceInHours(timeEnd, timeStart)) + 1;
    return { timeStart, diffHours };
  });

  for (let i = 0; i < diff; i++) {
    const day = add(date, durationSpecifier(i));

    if (
      // skip excluded days
      ["hour", "day"].includes(timeGranularity) &&
      dateRange.excluded.includes(day.getDay())
    ) {
      continue;
    }

    if (timeGranularity !== "hour") {
      timeline.push(day);
    } else {
      for (const { timeStart, diffHours } of timeRanges) {
        for (let j = 0; j < diffHours; j++) {
          const time = add(day, {
            hours: timeStart.getHours() + j * 1,
            minutes: timeStart.getMinutes(),
          });
          const lastTime = timeline.at(-1);
          // if we have a date already lets skip it
          if (lastTime && lastTime >= time) {
            continue;
          }
          timeline.push(time);
        }
      }
    }
  }
  return timeline;
}

export function buildCycleCountTimeline(
  timeline: Array<Date>,
  cycleCountByTimeMap: Record<string, number>,
  cycleCountTarget: { mean: number; total: number }
): Array<CycleCountByTime> {
  const cycleCountByTime = [];

  let accValue = 0;
  let accTarget = 0;
  let avgPerTimePoint = 0;
  let firstFutureIdx = 0;
  const avgTargetPerTimePoint = Math.round(
    cycleCountTarget.total / timeline.length
  );
  const now = new Date();

  for (let idx = 0; idx < timeline.length; idx++) {
    const date = timeline[idx];
    const key = dateToTrendDataKey(date);
    const value = cycleCountByTimeMap[key] || 0;

    if (date > now && firstFutureIdx === 0 && idx > 0) {
      firstFutureIdx = idx;
      const lastPastAccValue = cycleCountByTime[idx - 1].accValue;
      avgPerTimePoint = Math.round(lastPastAccValue / firstFutureIdx);
    }

    accValue += date > now ? avgPerTimePoint : value;
    accTarget += avgTargetPerTimePoint;

    cycleCountByTime.push({
      date,
      value,
      accTarget,
      accValue,
      target: 0,
    });
  }

  return cycleCountByTime;
}
