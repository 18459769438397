import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";

import {
  OverviewDrawer,
  OverviewDrawerContent,
  OverviewDrawerHeader,
} from "@/view/components";
import { useSelectedArea } from "@/view/pages/line-id-area-id/selected-area-provider";

import { useSelectedLine } from "../line-id/use-selected-line";
import { AreaActivity } from "./area-activity";
import { AreaDetailsFilters } from "./area-details-filters-provider";

export function AreaDetailsDrawer({ onClose }: { onClose: () => void }) {
  const { i18n } = useLingui();
  const selectedLine = useSelectedLine();
  const selectedAreaId = useSelectedArea();

  return (
    <OverviewDrawer onClose={onClose}>
      <OverviewDrawerHeader
        title={<Trans>Area {selectedAreaId}</Trans>}
        subTitle={selectedLine?.name || "---"}
      />

      <OverviewDrawerContent>
        <div className="flex items-center gap-4 mb-4">
          <h3 className="grow text-lg font-semibold">
            {i18n.t("areaOverviewActivity")}
          </h3>
          <AreaDetailsFilters />
        </div>

        <AreaActivity />
      </OverviewDrawerContent>
    </OverviewDrawer>
  );
}
