import { useLingui } from "@lingui/react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/view/components";
import { formatKpiValue, getKpiUnit } from "@/view/utils";

import { useLineStatistics } from "./line-statistics-provider";
import { LineWidgetDropdown } from "./line-widget-dropdown";

export function LineWidgetActivityError() {
  const { i18n } = useLingui();
  return (
    <Card className="p-4 h-44 grow border rounded-xl shadow-sm">
      <CardHeader>
        <CardTitle>{i18n.t("lineOverviewWidgetActivityTitle")}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription>
          <span className="text-5xl">--.--</span>
          <span className="text-2xl"> h</span>
        </CardDescription>
      </CardContent>
    </Card>
  );
}

export function LineWidgetActivity() {
  const { i18n } = useLingui();
  const data = useLineStatistics();
  const formattedValue = formatKpiValue("activity", data.activeTimePercentage);
  const valueUnit = getKpiUnit("activity", data.activeTimePercentage);

  return (
    <Card className="p-4 border rounded-xl shadow-sm h-44 flex-grow max-w-xl min-w-min">
      <CardHeader className="relative">
        <CardTitle>{i18n.t("lineOverviewWidgetActivityTitle")}</CardTitle>
        <div className="absolute top-0 right-0">
          <LineWidgetDropdown targetType="persons" />
        </div>
      </CardHeader>
      <CardContent>
        <CardDescription className="font-bold">
          <span className="text-5xl">{formattedValue}</span>
          <span className="text-2xl">{` ${valueUnit}`}</span>
        </CardDescription>
      </CardContent>
    </Card>
  );
}
