import { useLingui } from "@lingui/react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import {
  generatePath,
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom";

import { useUserGroups } from "@/view/providers/use-user-groups";
import { paths } from "@/view/routes";
import { cn, isAdmin } from "@/view/utils";

import { useSelectedLine } from "./use-selected-line";

const componentsMap = {
  manual: ManualLineMainNav,
  mixed: ManualLineMainNav,
  machine: MachineLineMainNav,
} as const;

export function MainNav() {
  const line = useSelectedLine();
  const Component = componentsMap[line.type];
  return <Component />;
}

function ManualLineMainNav() {
  const { i18n } = useLingui();
  const userGroups = useUserGroups();

  if (!userGroups) {
    return null;
  }

  return (
    <NavigationMenu.Root className="">
      <NavigationMenu.List className={cn("flex justify-center gap-2")}>
        <MenuItem
          href={paths.lineReportingPath}
          label={i18n.t("reportingRouteLabel")}
        />
        {isAdmin({ userGroups }) && (
          <MenuItem
            href={paths.lineTrendsPath}
            label={i18n.t("trendsRouteLabel")}
          />
        )}
        <MenuItem
          href={paths.lineVideoLibraryPath}
          label={i18n.t("videoLibraryRouteLabel")}
        />
        <MenuItem
          href={paths.lineBirdEyeViewPath}
          label={i18n.t("Spaghetti diagram")}
        />
        {isAdmin({ userGroups }) && (
          <MenuItem
            href={paths.lineCycleDebuggingPath}
            label={i18n.t("Cycle debugging")}
          />
        )}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}

function MachineLineMainNav() {
  const { i18n } = useLingui();
  const userGroups = useUserGroups();

  if (!userGroups) {
    return null;
  }

  return (
    <NavigationMenu.Root className="">
      <NavigationMenu.List className={cn("flex justify-center gap-2")}>
        <MenuItem
          href={paths.lineReportingPath}
          label={i18n.t("reportingRouteLabel")}
        />
        <MenuItem
          href={paths.lineVideoLibraryPath}
          label={i18n.t("videoLibraryRouteLabel")}
        />
        {isAdmin({ userGroups }) && (
          <MenuItem
            href={paths.lineCycleDebuggingPath}
            label={i18n.t("Cycle debugging")}
          />
        )}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}

function MenuItem({ href, label }: { href: string; label: string }) {
  const navigate = useNavigate();
  const { lineId = "" } = useParams<{ lineId: string }>();
  const path = generatePath(href, { lineId });
  return (
    <NavigationMenu.Item>
      <NavigationMenu.Link asChild>
        <NavLink
          to={path}
          className="focus:outline-none"
          onClick={(e) => {
            e.preventDefault();
            navigate(path + location.search);
          }}
        >
          {({ isActive }) => (
            <span
              className={cn(
                "block px-4 py-2 rounded-md",
                "text-base text-brand-gray-5 font-semibold",
                "hover:text-brand-black",
                {
                  "bg-brand-gray-1": isActive,
                }
              )}
            >
              {label}
            </span>
          )}
        </NavLink>
      </NavigationMenu.Link>
    </NavigationMenu.Item>
  );
}
