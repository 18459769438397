import { fetchAuthSession } from "aws-amplify/auth";
import { format, parse, parseISO as parseIsoFns, startOfDay } from "date-fns";
import { GraphQLClient } from "graphql-request";

import {
  ApiClient,
  PaginatedResponse,
  RequestConfig,
} from "@/domain/api-client";
import {
  AreaConnections,
  areaConnectionsSchema,
  AreaCoordinatesByAreaId,
  AreaId,
  areaOfInterestsStatisticsSchema,
  CameraId,
  convertRelativeToAbsoluteCoordinates,
  GetAreaConnectionsFilters,
  GetAreaOfInterestStatisticsFilters,
  GetHeatmapByCameraIdFilters,
  GetVirtualSensorAreasLayoutByLineIdFilters,
  GetVirtualSensorImageByLineIdFilters,
  type Heatmap,
  heatmapSchema,
  relAreaCoordinates,
  VirtualSensorAreasLayout,
  virtualSensorAreasLayoutSchema,
  VirtualSensorImage,
} from "@/domain/areas-of-interests";
import {
  ApiError,
  BadRequestError,
  NetworkError,
  NotFoundError,
  ResponseParsingError,
  UnauthorizedError,
} from "@/domain/common/errors";
import {
  dateToTrendDataKey,
  KpiMetric,
  KpiType,
  KpiValueType,
} from "@/domain/common/metrics";
import { DateRangeFilter, TimeRangeFilter } from "@/domain/common/time-filter";
import {
  cycleTypeSchema,
  eventTypeSchema,
  StationCyclesAndEvents,
  StationCyclesAndEventsFilters,
  stationCyclesAndEventsSchema,
  timeWindowTypeSchema,
} from "@/domain/cycles";
import { FactoryId } from "@/domain/factory";
import { OperationalLevels } from "@/domain/levels";
import { LineId, LineWithMetrics, lineWithMetricsSchema } from "@/domain/line";
import {
  Notification,
  NotificationByIdFilters,
  NotificationFilters,
  NotificationId,
  NotificationRule,
  NotificationRuleId,
  notificationRuleSchema,
  NotificationRulesFilters,
  NotificationUpdateParams,
} from "@/domain/notification";
import {
  Shift,
  ShiftId,
  ShiftsWithVariantsFilters,
  ShiftVariant,
  ShiftVariantId,
  ShiftVariantOverride,
  shiftVariantSchema,
  ShiftWithVariants,
  unflattenArray,
} from "@/domain/shifts";
import {
  StationId,
  stationIdSchema,
  StationType,
  StationWithMetrics,
  stationWithMetricsSchema,
} from "@/domain/station";
import {
  buildCycleCountTimeline,
  buildTimeline,
  cycleCountByStationSchema,
  CycleMeanTimeByTime,
  CycleVarianceByTime,
  CycleVarianceKeySchema,
  cycleVarianceKeySchema,
  GetLineActivityPerStationStatisticsFilters,
  GetLineOutputPerStationStatisticsFilters,
  GetLineOverallStatisticsFilters,
  LineAccumulatedByTimeStatistics,
  lineAccumulatedByTimeStatisticsSchema,
  LineActivityPerStationStatistics,
  lineActivityPerStationStatisticsSchema,
  LineAverageCycleTimeByStation,
  lineAverageCycleTimeByStationSchema,
  LineOutputPerStationStatistics,
  lineOutputPerStationStatisticsSchema,
  LineOverallStatistics,
  lineOverallStatisticsSchema,
  LineStatisticsFilters,
  LinesWithMetricsFilters,
  StationStatistics,
  StationStatisticsFilters,
  stationStatisticsSchema,
  StationsWithMetricsFilters,
  TimeGranularity,
} from "@/domain/statistics";
import {
  CreateTagParamsSchema,
  GetAllTagsFilters,
  Tag,
  TagId,
  tagIdSchema,
  TagType,
} from "@/domain/tag";
import { TracingClient } from "@/domain/tracing";
import {
  UserPreferences,
  userPreferencesSchema,
} from "@/domain/user-preferences";
import {
  TagVideoParamsSchema,
  UpdateVideoDescriptionParamsSchema,
  Video,
  VideoBookmarkParamsSchema,
  VideoId,
  VideosFilters,
  VideoTagDto,
} from "@/domain/video";

import {
  CreateNotificationRuleDocument,
  CreateShiftVariantDocument,
  CreateShiftVariantOverrideDocument,
  CreateTagDocument,
  CreateVideoBookmarkDocument,
  DeleteNotificationRuleDocument,
  DeleteShiftVariantDocument,
  DeleteVideoBookmarkDocument,
  GetAllTagsDocument,
  GetAnonymizedVideosDocument,
  GetAreaConnectionsDocument,
  GetAreaOfInterestsStatisticsDocument,
  GetFactoriesWithLinesAndStationsDocument,
  GetHeatmapByCameraIdDocument,
  GetLineActivityPerStationStatisticsDocument,
  GetLineAverageCycleTimeByStationDocument,
  GetLineOutputPerStationStatisticsDocument,
  GetLineOverallStatisticsDocument,
  GetLineStatisticsAccumulatedByTimeDocument,
  GetLinesWithMetricsDocument,
  GetNotificationByPkDocument,
  GetNotificationRulesDocument,
  GetNotificationsDocument,
  GetShiftsDocument,
  GetStationCyclesAndEventsDocument,
  GetStationStatisticsDocument,
  GetStationsWithMetricsDocument,
  GetUserPreferencesDocument,
  GetVirtualSensorAreasLayoutByLineIdDocument,
  GetVirtualSensorImageByLineIdDocument,
  ICreateNotificationRuleMutation,
  ICreateNotificationRuleMutationVariables,
  ICreateShiftVariantMutation,
  ICreateShiftVariantMutationVariables,
  ICreateShiftVariantOverrideMutation,
  ICreateShiftVariantOverrideMutationVariables,
  ICreateTagMutation,
  ICreateTagMutationVariables,
  ICreateVideoBookmarkMutation,
  ICreateVideoBookmarkMutationVariables,
  IDeleteNotificationRuleMutation,
  IDeleteNotificationRuleMutationVariables,
  IDeleteShiftVariantMutation,
  IDeleteShiftVariantMutationVariables,
  IDeleteVideoBookmarkMutation,
  IDeleteVideoBookmarkMutationVariables,
  IGetAllTagsQuery,
  IGetAllTagsQueryVariables,
  IGetAnonymizedVideosQuery,
  IGetAnonymizedVideosQueryVariables,
  IGetAreaConnectionsQuery,
  IGetAreaConnectionsQueryVariables,
  IGetAreaOfInterestsStatisticsQuery,
  IGetAreaOfInterestsStatisticsQueryVariables,
  IGetFactoriesWithLinesAndStationsQuery,
  IGetFactoriesWithLinesAndStationsQueryVariables,
  IGetHeatmapByCameraIdQuery,
  IGetHeatmapByCameraIdQueryVariables,
  IGetLineActivityPerStationStatisticsQuery,
  IGetLineActivityPerStationStatisticsQueryVariables,
  IGetLineAverageCycleTimeByStationQuery,
  IGetLineAverageCycleTimeByStationQueryVariables,
  IGetLineOutputPerStationStatisticsQuery,
  IGetLineOutputPerStationStatisticsQueryVariables,
  IGetLineOverallStatisticsQuery,
  IGetLineOverallStatisticsQueryVariables,
  IGetLineStatisticsAccumulatedByTimeQuery,
  IGetLineStatisticsAccumulatedByTimeQueryVariables,
  IGetLinesWithMetricsQuery,
  IGetLinesWithMetricsQueryVariables,
  IGetNotificationByPkQuery,
  IGetNotificationByPkQueryVariables,
  IGetNotificationRulesQuery,
  IGetNotificationRulesQueryVariables,
  IGetNotificationsQuery,
  IGetNotificationsQueryVariables,
  IGetShiftsQuery,
  IGetShiftsQueryVariables,
  IGetStationCyclesAndEventsQuery,
  IGetStationCyclesAndEventsQueryVariables,
  IGetStationStatisticsQuery,
  IGetStationStatisticsQueryVariables,
  IGetStationsWithMetricsQuery,
  IGetStationsWithMetricsQueryVariables,
  IGetUserPreferencesQuery,
  IGetUserPreferencesQueryVariables,
  IGetVirtualSensorAreasLayoutByLineIdQuery,
  IGetVirtualSensorAreasLayoutByLineIdQueryVariables,
  IGetVirtualSensorImageByLineIdQuery,
  IGetVirtualSensorImageByLineIdQueryVariables,
  IKpiMetric,
  IKpiMetricType,
  IKpiType,
  IKpiValueType,
  IMediumType,
  IOrdering,
  IRelationType,
  IRuleType,
  ISaveUserPreferencesMutation,
  ISaveUserPreferencesMutationVariables,
  IShiftVariant,
  IStationTypes,
  ITag,
  ITagVideoMutation,
  ITagVideoMutationVariables,
  IThresholdType,
  ITimeGranularity,
  IUntagVideoMutation,
  IUntagVideoMutationVariables,
  IUpdateNotificationMutation,
  IUpdateNotificationMutationVariables,
  IUpdateNotificationRuleMutation,
  IUpdateNotificationRuleMutationVariables,
  IUpdateShiftVariantMutation,
  IUpdateShiftVariantMutationVariables,
  IUpdateVideoDescriptionMutation,
  IUpdateVideoDescriptionMutationVariables,
  SaveUserPreferencesDocument,
  TagVideoDocument,
  UntagVideoDocument,
  UpdateNotificationDocument,
  UpdateNotificationRuleDocument,
  UpdateShiftVariantDocument,
  UpdateVideoDescriptionDocument,
} from "./graphql/sdk";

const isProductionBuild = import.meta.env.PROD;

enum HttpStatus {
  Ok = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  InternalServerError = 500,
}

const gqlClient = new GraphQLClient(`${window.location.origin}/api/graphql`, {
  async requestMiddleware(request) {
    const headers = new Headers(request.headers);
    headers.set("Content-Type", "application/json");
    headers.set("Authorization", `Bearer ${await getAccessToken()}`);
    request.method = "POST";
    request.headers = headers;
    return request;
  },
});

async function getAccessToken() {
  try {
    const session = await fetchAuthSession();
    return session.tokens?.accessToken.toString() ?? "";
  } catch (_error) {
    return "";
  }
}

export function createGraphQlApiClient(
  tracingClient: TracingClient
): ApiClient {
  async function catchRequestErrors<T>(request: () => Promise<T>) {
    try {
      return await request();
    } catch (error) {
      if (error instanceof Error) {
        tracingClient.captureException(error);
      }

      const status =
        (error as { response: { status: number } })?.response?.status ?? 500;

      if (status >= HttpStatus.InternalServerError) {
        throw new NetworkError();
      } else if (
        status == HttpStatus.Unauthorized ||
        status == HttpStatus.Forbidden
      ) {
        throw new UnauthorizedError();
      } else if (status >= HttpStatus.BadRequest) {
        throw new BadRequestError();
      } else {
        throw new ApiError();
      }
    }
  }

  /**
   * Get lines with metrics.
   */
  async function getLinesWithMetrics(
    params: LinesWithMetricsFilters,
    config?: RequestConfig
  ) {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetLinesWithMetricsQuery,
        IGetLinesWithMetricsQueryVariables
      >({
        document: GetLinesWithMetricsDocument,
        variables: {
          ...parseDateRangeParams(params),
          factoryId: params.factoryId,
        },
        signal: config?.signal,
      })
    );

    return parseLineData(response.linesWithMetrics, tracingClient);
  }

  /**
   * Get stations with metrics.
   */
  async function getStationsWithMetrics(
    params: StationsWithMetricsFilters,
    config?: RequestConfig
  ): ReturnType<ApiClient["getStationsWithMetrics"]> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetStationsWithMetricsQuery,
        IGetStationsWithMetricsQueryVariables
      >({
        document: GetStationsWithMetricsDocument,
        variables: {
          ...parseDateRangeParams(params),
          lineId: params.lineId,
        },
        signal: config?.signal,
      })
    );

    return parseStationData(response.stationsWithMetrics, tracingClient);
  }

  /**
   * Get factories with lines and station information.
   */
  async function getOperationalLevels(
    config?: RequestConfig
  ): Promise<OperationalLevels> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetFactoriesWithLinesAndStationsQuery,
        IGetFactoriesWithLinesAndStationsQueryVariables
      >({
        document: GetFactoriesWithLinesAndStationsDocument,
        signal: config?.signal,
      })
    );
    return response.organizationalLevels.map((factory) => ({
      ...factory,
      id: factory.id as FactoryId,
      lines: factory.lines.map((line) => ({
        ...line,
        id: line.id as LineId,
        factoryId: factory.id as FactoryId,
        inTraining: line.inTraining ?? false,
        shifts: parseShiftsData(line.shifts),
        type: line.stations.every(
          (station) => parseStationType(station.type) === "machine"
        )
          ? "machine"
          : "manual",
        stations: line.stations.map((station) => ({
          ...station,
          id: station.id as StationId,
          factoryId: factory.id as FactoryId,
          lineId: line.id as LineId,
          isOutput: station.isOutput,
          type: parseStationType(station.type),
        })),
      })),
    }));
  }

  /**
   * Get shifts with variants for a line.
   */
  async function getShiftsWithVariants(
    params: ShiftsWithVariantsFilters,
    config?: RequestConfig
  ): Promise<Array<ShiftWithVariants>> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<IGetShiftsQuery, IGetShiftsQueryVariables>({
        document: GetShiftsDocument,
        variables: {
          lineId: params.lineId,
          applicableDate: params.applicableDate
            ? formatDate(params.applicableDate)
            : null,
        },
        signal: config?.signal,
      })
    );

    return response.shifts.data.map((shift) => ({
      id: shift.id as ShiftId,
      name: shift.name,
      start: parse(shift.start, "HH:mm:ss", new Date()),
      end: parse(shift.end, "HH:mm:ss", new Date()),
      variants: shift.variants.map((variant) =>
        parseShiftVariant(variant, shift.id as ShiftId)
      ),
    }));
  }

  /**
   * Get videos for context metrics.
   */
  async function getVideosForStations(
    params: VideosFilters,
    config?: RequestConfig
  ): Promise<PaginatedResponse<Video>> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetAnonymizedVideosQuery,
        IGetAnonymizedVideosQueryVariables
      >({
        document: GetAnonymizedVideosDocument,
        variables: {
          stationIds: params.stationIds,
          shiftGroupIds: params.shiftIds,
          tagIds: params.tagIds,
          dateRange: [
            formatDate(params.dateRange.values.start),
            formatDate(params.dateRange.values.end),
          ],
          durationRange: params.durationRange,
          onlyBookmarks: params.onlyBookmarks,
          order: params.order === "DESC" ? IOrdering.Desc : IOrdering.Asc,
          limit: params.limit,
          offset: params.offset,
        },
        signal: config?.signal,
      })
    );

    return {
      data: response.anonymizedVideos.videos.map((v) => ({
        id: v.id as VideoId,
        description: v.description ? v.description.trim() : null,
        duration: v.duration,
        timestampStart: parseISO(v.timestampStart),
        timestampEnd: parseISO(v.timestampEnd),
        url: v.video.presignedUrl,
        bookmarked: v.bookmark !== null,
        tagIds: v.tagIds as Array<TagId>,
      })),
      offset: response.anonymizedVideos.offset,
      limit: response.anonymizedVideos.limit,
      totalCount: response.anonymizedVideos.totalCount,
    };
  }

  /**
   * Get all tags for videos.
   */
  async function getAllTags(
    params: GetAllTagsFilters,
    config?: RequestConfig
  ): Promise<Array<Tag>> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<IGetAllTagsQuery, IGetAllTagsQueryVariables>({
        document: GetAllTagsDocument,
        variables: {},
        signal: config?.signal,
        requestHeaders: {
          "X-Line-Id": params.lineId,
        },
      })
    );

    return response.tags.map((t) => ({
      id: t.id as TagId,
      name: t.name,
      color: `rgb(${t.color.join(",")})`,
      type: parseTagType(t.tagType),
    }));
  }

  /**
   * Get paginated notifications.
   */
  async function getNotifications(
    params: NotificationFilters,
    config?: RequestConfig
  ): Promise<PaginatedResponse<Notification>> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetNotificationsQuery,
        IGetNotificationsQueryVariables
      >({
        document: GetNotificationsDocument,
        variables: {
          ...params,
          // get only the past notifications
          publishedAt: new Date().toISOString(),
        },
        signal: config?.signal,
      })
    );

    return {
      data: response.notifications.data.map((it) => ({
        id: it.id as NotificationId,
        isRead: it.isRead,
        publishedAt: parseISO(it.publishedAt),
        notificationRule: parseNotificationRuleDto(it.notificationRule),
      })),
      offset: response.notifications.offset,
      limit: response.notifications.limit,
      totalCount: response.notifications.totalCount,
    };
  }

  /**
   * Get notification by ID.
   */
  async function getNotificationById(
    params: NotificationByIdFilters,
    config?: RequestConfig
  ): Promise<Notification> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetNotificationByPkQuery,
        IGetNotificationByPkQueryVariables
      >({
        document: GetNotificationByPkDocument,
        variables: {
          notificationId: params.id,
        },
        signal: config?.signal,
      })
    );

    if (response.notificationByPk == null) {
      throw new NotFoundError();
    }

    return {
      id: response.notificationByPk.id as NotificationId,
      isRead: response.notificationByPk.isRead,
      publishedAt: parseISO(response.notificationByPk.publishedAt),
      notificationRule: parseNotificationRuleDto(
        response.notificationByPk.notificationRule
      ),
    };
  }

  /**
   * Update notification `isRead` flag or it's `publishedAt` date.
   */
  async function updateNotification(
    params: NotificationUpdateParams,
    config?: RequestConfig
  ): Promise<Notification> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IUpdateNotificationMutation,
        IUpdateNotificationMutationVariables
      >({
        document: UpdateNotificationDocument,
        variables: {
          notificationId: params.id,
          isRead: params.isRead,
          publishedAt: params.publishedAt
            ? params.publishedAt.toISOString()
            : null,
        },
        signal: config?.signal,
      })
    );

    if (response.updateNotification.__typename == "NotificationEventNotFound") {
      if (!isProductionBuild) {
        console.error(response.updateNotification.message);
      }
      throw new NotFoundError();
    }

    return {
      id: response.updateNotification.id as NotificationId,
      isRead: response.updateNotification.isRead,
      publishedAt: parseISO(response.updateNotification.publishedAt),
      notificationRule: parseNotificationRuleDto(
        response.updateNotification.notificationRule
      ),
    };
  }

  async function getLineOverallStatistics(
    params: GetLineOverallStatisticsFilters,
    config?: RequestConfig
  ): Promise<LineOverallStatistics> {
    const { dateRanges, exludedWeekdays } = parseDateRangeParams(params);
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetLineOverallStatisticsQuery,
        IGetLineOverallStatisticsQueryVariables
      >({
        document: GetLineOverallStatisticsDocument,
        variables: {
          dateRange: dateRanges,
          exludedWeekdays,
          shiftGroupIds: params.shiftIds,
          tagIds: params.tagIds,
          lineId: params.lineId,
          productIds: params.productIds ?? [],
          stationIds: params.stationIds ?? [],
        },
        signal: config?.signal,
      })
    );

    if (response.lineOverallStatistics == null) {
      throw new NotFoundError();
    }

    if (
      response.lineOverallStatistics.__typename !== "LineOverallStatisticsDTO"
    ) {
      if (!isProductionBuild) {
        console.error(response.lineOverallStatistics.message);
      }
      throw new NotFoundError();
    }

    return lineOverallStatisticsSchema.parse(response.lineOverallStatistics);
  }

  async function getLineStatisticsAccumulatedByTime(
    params: LineStatisticsFilters,
    config?: RequestConfig
  ) {
    const { dateRanges, exludedWeekdays } = parseDateRangeParams(params);
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetLineStatisticsAccumulatedByTimeQuery,
        IGetLineStatisticsAccumulatedByTimeQueryVariables
      >({
        document: GetLineStatisticsAccumulatedByTimeDocument,
        variables: {
          dateRange: dateRanges,
          excludedWeekdays: exludedWeekdays,
          shiftGroupIds: params.shiftIds,
          tagIds: params.tagIds,
          lineId: params.lineId,
        },
        signal: config?.signal,
      })
    );

    if (response.lineAccumulatedByTimeStatistics == null) {
      throw new NotFoundError();
    }

    if (
      response.lineAccumulatedByTimeStatistics.__typename !==
      "LineAccumulatedByTimeStatisticsDTO"
    ) {
      if (!isProductionBuild) {
        console.error(response.lineAccumulatedByTimeStatistics.message);
      }
      throw new NotFoundError();
    }

    const transformedData: LineAccumulatedByTimeStatistics = {
      timeGranularity: parseTimeGranularity(
        response.lineAccumulatedByTimeStatistics.timeGranularity
      ),
      workingHours:
        response.lineAccumulatedByTimeStatistics.workingHours?.map(
          ([start, end]) => [parseISO(start), parseISO(end)]
        ) ?? null,
      data: response.lineAccumulatedByTimeStatistics.data.map(
        ({ aggregationCount, aggregationTarget, datetime }) => ({
          aggregationCount,
          aggregationTarget,
          /**
           * datetime comes in ISO string format "2024-09-01T00:00:00.000". zod schema supports only string "2024-09-01T00:00:00.000Z".
           * we could use zod to parse and transform the date, but the format is not supported by zod.
           * */
          datetime: parseISO(datetime),
        })
      ),
    };

    return lineAccumulatedByTimeStatisticsSchema.parse(transformedData);
  }

  async function getLineOutputPerStationStatistics(
    params: GetLineOutputPerStationStatisticsFilters,
    config?: RequestConfig
  ) {
    const { dateRanges, exludedWeekdays } = parseDateRangeParams(params);
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetLineOutputPerStationStatisticsQuery,
        IGetLineOutputPerStationStatisticsQueryVariables
      >({
        document: GetLineOutputPerStationStatisticsDocument,
        variables: {
          dateRange: dateRanges,
          excludedWeekdays: exludedWeekdays,
          shiftGroupIds: params.shiftIds,
          tagIds: params.tagIds,
          productIds: params.productIds ?? [],
          lineId: params.lineId,
        },
        signal: config?.signal,
      })
    );

    if (response.lineOutputPerStationStatistics == null) {
      throw new NotFoundError();
    }

    if (
      response.lineOutputPerStationStatistics.__typename !==
      "LineOutputPerStationStatisticsDTO"
    ) {
      if (!isProductionBuild) {
        console.error(response.lineOutputPerStationStatistics.message);
      }
      throw new NotFoundError();
    }

    const transformedData: LineOutputPerStationStatistics = {
      data: response.lineOutputPerStationStatistics.data.map((c) =>
        cycleCountByStationSchema.parse({
          stationId: c.stationId,
          single: replaceNegativeValuesWithZero(c.single, c.stationId),
          combined: replaceNegativeValuesWithZero(c.combined, c.stationId),
          compared: replaceNegativeValuesWithZero(c.compared, c.stationId),
        })
      ),
    };

    return lineOutputPerStationStatisticsSchema.parse(transformedData);
  }

  async function getLineAverageCycleTimeByStation(
    params: LineStatisticsFilters,
    config?: RequestConfig
  ): Promise<LineAverageCycleTimeByStation> {
    const { dateRanges, exludedWeekdays } = parseDateRangeParams(params);
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetLineAverageCycleTimeByStationQuery,
        IGetLineAverageCycleTimeByStationQueryVariables
      >({
        document: GetLineAverageCycleTimeByStationDocument,
        variables: {
          dateRange: dateRanges,
          excludedWeekdays: exludedWeekdays,
          shiftGroupIds: params.shiftIds,
          tagIds: params.tagIds,
          lineId: params.lineId,
          productIds: params.productIds ?? [],
        },
        signal: config?.signal,
      })
    );

    if (response.lineAverageCycleTimeByStation == null) {
      throw new NotFoundError();
    }

    if (
      response.lineAverageCycleTimeByStation.__typename !==
      "LineAverageCycleTimeByStationDTO"
    ) {
      if (!isProductionBuild) {
        console.error(response.lineAverageCycleTimeByStation.message);
      }
      throw new NotFoundError();
    }

    const data: LineAverageCycleTimeByStation = {
      taktTime: response.lineAverageCycleTimeByStation.taktTime,
      avgCycleTimeByStation: {},
    };

    for (const c of response.lineAverageCycleTimeByStation.data) {
      const stationId = stationIdSchema.parse(c.stationId);

      data.avgCycleTimeByStation[stationId] = {
        stationId,
        single: replaceNegativeValuesWithZero(c.single, stationId),
        compared: replaceNegativeValuesWithZero(c.compared, stationId),
      };
    }

    try {
      return lineAverageCycleTimeByStationSchema.parse(data);
    } catch (error) {
      if (!isProductionBuild) {
        console.error(error);
      }
      throw new ResponseParsingError();
    }
  }

  async function getLineActivityPerStationStatistics(
    params: GetLineActivityPerStationStatisticsFilters,
    config?: RequestConfig
  ): Promise<LineActivityPerStationStatistics> {
    const { dateRanges, exludedWeekdays } = parseDateRangeParams(params);
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetLineActivityPerStationStatisticsQuery,
        IGetLineActivityPerStationStatisticsQueryVariables
      >({
        document: GetLineActivityPerStationStatisticsDocument,
        variables: {
          dateRange: dateRanges,
          excludedWeekdays: exludedWeekdays,
          shiftGroupIds: params.shiftIds,
          lineId: params.lineId,
        },
        signal: config?.signal,
      })
    );

    if (response.lineActivityPerStationStatistics == null) {
      throw new NotFoundError();
    }

    if (
      response.lineActivityPerStationStatistics.__typename !==
      "LineActivityPerStationStatisticsDTO"
    ) {
      if (!isProductionBuild) {
        console.error(response.lineActivityPerStationStatistics.message);
      }
      throw new NotFoundError();
    }

    const activityByStationId: LineActivityPerStationStatistics = {};

    for (const c of response.lineActivityPerStationStatistics.data) {
      const stationId = stationIdSchema.parse(c.stationId);

      activityByStationId[stationId] = c.data.map((it) => ({
        productId: tagIdSchema.parse(it.tagId),
        data: replaceNegativeValuesWithZero(it.data, stationId),
      }));
    }

    try {
      return lineActivityPerStationStatisticsSchema.parse(activityByStationId);
    } catch (error) {
      if (!isProductionBuild) {
        console.error(error);
      }
      throw new ResponseParsingError();
    }
  }

  /**
   * Get station statistics.
   */
  async function getStationStatistics(
    params: StationStatisticsFilters,
    config?: RequestConfig
  ): Promise<StationStatistics> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetStationStatisticsQuery,
        IGetStationStatisticsQueryVariables
      >({
        document: GetStationStatisticsDocument,
        variables: {
          stationId: params.stationId,
          shiftGroupIds: params.shifts.map((it) => it.id),
          dateRange: [
            formatDate(params.dateRange.values.start),
            formatDate(params.dateRange.values.end),
          ],
        },
        signal: config?.signal,
      })
    );

    if (
      response.cycleOverTimeStatistics.__typename !== "CycleOverTimeStatistics"
    ) {
      if (!isProductionBuild) {
        console.error(response.cycleOverTimeStatistics.message);
      }
      throw new BadRequestError();
    }

    if (
      !response.histogram ||
      response.histogram.__typename !== "StationHistogram"
    ) {
      throw new NotFoundError();
    }

    const cycleCountByTimeMap: Record<string, number> = {};
    const cycleDurationByTime: Record<string, number> = {};
    const cycleMeanTimeByTime: Record<string, CycleMeanTimeByTime> = {};
    const cycleVarianceByTime: Record<
      CycleVarianceKeySchema,
      CycleVarianceByTime
    > = {};

    const timeGranularity = parseTimeGranularity(
      response.cycleOverTimeStatistics.timeGranularity
    );

    for (const c of response.cycleOverTimeStatistics.data) {
      const date = parseISO(c.datetime);
      const key = dateToTrendDataKey(
        timeGranularity !== "hour" ? startOfDay(date) : date
      );
      cycleCountByTimeMap[key] = c.count;
      cycleDurationByTime[key] = c.totalDuration;
    }

    const timeline = buildTimeline(
      params.dateRange,
      params.shifts,
      timeGranularity
    );
    const cycleCountByTime = buildCycleCountTimeline(
      timeline,
      cycleCountByTimeMap,
      // should we have a target for the line as a target for each station
      { mean: 0, total: 0 }
    );

    for (const date of timeline) {
      const key = dateToTrendDataKey(date);
      const count = cycleCountByTimeMap[key] ?? 0;
      const totalDuration = cycleDurationByTime[key] ?? 0;
      cycleMeanTimeByTime[key] = {
        date,
        value: count > 0 ? Math.floor(totalDuration / count) : 0,
      };
    }

    for (const c of response.histogram.data) {
      const key = cycleVarianceKeySchema.parse(`${c.range[0]}__${c.range[1]}`);
      cycleVarianceByTime[key] = {
        range: c.range as [number, number], // it's fine, we validate below
        value: c.cycleCount,
      };
    }

    try {
      const data: StationStatistics = {
        timeGranularity,
        cycleCount: response.histogram.cycleCount,
        cycleMeanTime: response.histogram.duration.mean,
        cycleMedianTime: response.histogram.duration.median,
        cycleTargetTime: response.histogram.durationTarget.mean,
        cycleTimeVariance: response.histogram.duration.variance,
        cycleCountByTime,
        cycleMeanTimeByTime,
        cycleVarianceByTime,
      };
      return stationStatisticsSchema.parse(data);
    } catch (error) {
      if (!isProductionBuild) {
        console.error(error);
      }
      throw new ResponseParsingError();
    }
  }

  /**
   * Update video description.
   */
  async function updateVideoDescription(
    params: UpdateVideoDescriptionParamsSchema,
    config?: RequestConfig
  ): Promise<void> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IUpdateVideoDescriptionMutation,
        IUpdateVideoDescriptionMutationVariables
      >({
        document: UpdateVideoDescriptionDocument,
        variables: params,
        signal: config?.signal,
      })
    );

    if (response.updateAnonymizedVideo.__typename !== "AnonymizedVideo") {
      throw new ApiError();
    }
  }

  /**
   * Create video bookmark.
   */
  async function createVideoBookmark(
    params: VideoBookmarkParamsSchema,
    config?: RequestConfig
  ): Promise<void> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        ICreateVideoBookmarkMutation,
        ICreateVideoBookmarkMutationVariables
      >({
        document: CreateVideoBookmarkDocument,
        variables: params,
        signal: config?.signal,
      })
    );

    if (response.createVideoBookmark.__typename !== "AnonymizedVideoBookmark") {
      if (!isProductionBuild) {
        console.error(response.createVideoBookmark.message);
      }
      throw new ApiError();
    }
  }

  /**
   * Delete video bookmark.
   */
  async function deleteVideoBookmark(
    params: VideoBookmarkParamsSchema,
    config?: RequestConfig
  ): Promise<void> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IDeleteVideoBookmarkMutation,
        IDeleteVideoBookmarkMutationVariables
      >({
        document: DeleteVideoBookmarkDocument,
        variables: params,
        signal: config?.signal,
      })
    );

    if (
      response.deleteVideoBookmark.__typename !==
      "DeletedAnonymizedVideoBookmarkId"
    ) {
      if (!isProductionBuild) {
        console.error(response.deleteVideoBookmark.message);
      }
      throw new ApiError();
    }
  }

  /**
   * Create tag.
   */
  async function createTag(
    params: CreateTagParamsSchema,
    config?: RequestConfig
  ): Promise<VideoTagDto> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<ICreateTagMutation, ICreateTagMutationVariables>({
        document: CreateTagDocument,
        variables: {
          name: params.name,
        },
        signal: config?.signal,
        requestHeaders: {
          "X-Line-Id": params.lineId,
        },
      })
    );

    if (response.createTag.__typename !== "Tag") {
      if (!isProductionBuild) {
        console.error(response.createTag.message);
      }
      throw new ApiError();
    }

    return {
      id: response.createTag.id as TagId,
      name: response.createTag.name,
      color: `rgb(${response.createTag.color.join(",")})`,
      type: "User",
      modelIds: [],
    };
  }

  /**
   * Tag video.
   */
  async function tagVideo(
    params: TagVideoParamsSchema,
    config?: RequestConfig
  ): Promise<void> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<ITagVideoMutation, ITagVideoMutationVariables>({
        document: TagVideoDocument,
        variables: {
          tagId: params.tagId,
          taggedModelId: params.videoId,
        },
        signal: config?.signal,
      })
    );

    if (response.tagModel.__typename !== "TagTaggable") {
      if (!isProductionBuild) {
        console.error(response.tagModel.message);
      }
      throw new ApiError();
    }
  }

  /**
   * Untag video.
   */
  async function untagVideo(
    params: TagVideoParamsSchema,
    config?: RequestConfig
  ): Promise<void> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<IUntagVideoMutation, IUntagVideoMutationVariables>({
        document: UntagVideoDocument,
        variables: {
          tagId: params.tagId,
          modelId: params.videoId,
        },
        signal: config?.signal,
      })
    );

    if (response.untagModel.__typename !== "UntagModelId") {
      if (!isProductionBuild) {
        console.error(response.untagModel.message);
      }
      throw new ApiError();
    }
  }

  /**
   * Create shift variant.
   */
  async function createShiftVariant(
    params: Omit<ShiftVariant, "id">,
    config?: RequestConfig
  ): Promise<ShiftVariant> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        ICreateShiftVariantMutation,
        ICreateShiftVariantMutationVariables
      >({
        document: CreateShiftVariantDocument,
        variables: {
          input: {
            id: null,
            appliedAt: formatDate(new Date()),
            shiftGroup: {
              id: params.shiftId,
            },
            name: params.name,
            disabled: !params.enabled,
            repeatStart: formatDate(params.repeatStart),
            repeatEvery: params.repeatEvery,
            repeatInterval: params.repeatInterval,
            weekdaysTargets: params.weekdaysWithTargets,
            workingTimes: unflattenArray([
              formatTime(params.start),
              ...params.breaks.flatMap((time) => [
                formatTime(time.start),
                formatTime(time.end),
              ]),
              formatTime(params.end),
            ]),
          },
        },
        signal: config?.signal,
      })
    );

    if (response.createShiftVariant.__typename !== "ShiftVariant") {
      if (!isProductionBuild) {
        console.error(response.createShiftVariant.message);
      }
      throw new ApiError(response.createShiftVariant.message);
    }

    return parseShiftVariant(response.createShiftVariant, params.shiftId);
  }

  /**
   * Update shift variant.
   */
  async function updateShiftVariant(
    params: ShiftVariant,
    config?: RequestConfig
  ): Promise<ShiftVariant> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IUpdateShiftVariantMutation,
        IUpdateShiftVariantMutationVariables
      >({
        document: UpdateShiftVariantDocument,
        variables: {
          input: {
            id: params.id,
            appliedAt: formatDate(new Date()),
            name: params.name,
            disabled: !params.enabled,
            repeatStart: formatDate(params.repeatStart),
            repeatEvery: params.repeatEvery,
            repeatInterval: params.repeatInterval,
            weekdaysTargets: params.weekdaysWithTargets,
            workingTimes: unflattenArray([
              formatTime(params.start),
              ...params.breaks.flatMap((time) => [
                formatTime(time.start),
                formatTime(time.end),
              ]),
              formatTime(params.end),
            ]),
          },
        },
        signal: config?.signal,
      })
    );

    if (response.updateShiftVariant.__typename !== "ShiftVariant") {
      if (!isProductionBuild) {
        console.error(response.updateShiftVariant.message);
      }
      throw new ApiError(response.updateShiftVariant.message);
    }

    return parseShiftVariant(response.updateShiftVariant, params.shiftId);
  }

  /**
   * Create shift variant override.
   */
  async function createShiftVariantOverride(
    params: ShiftVariantOverride,
    config?: RequestConfig
  ): Promise<void> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        ICreateShiftVariantOverrideMutation,
        ICreateShiftVariantOverrideMutationVariables
      >({
        document: CreateShiftVariantOverrideDocument,
        variables: {
          input: {
            id: params.id,
            date: formatDate(params.date),
            targets: params.targets,
          },
        },
        signal: config?.signal,
      })
    );

    if (
      response.createShiftVariantOverride.__typename !==
      "CreatedShiftVariantOverrideId"
    ) {
      if (!isProductionBuild) {
        console.error(response.createShiftVariantOverride.message);
      }
      throw new ApiError(response.createShiftVariantOverride.message);
    }
  }

  /**
   * Remove shift variant.
   */
  async function deleteShiftVariant(
    params: ShiftVariant,
    config?: RequestConfig
  ): Promise<void> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IDeleteShiftVariantMutation,
        IDeleteShiftVariantMutationVariables
      >({
        document: DeleteShiftVariantDocument,
        variables: {
          input: {
            id: params.id,
            appliedAt: formatDate(new Date()),
          },
        },
        signal: config?.signal,
      })
    );

    if (response.deleteShiftVariant.__typename !== "DeletedShiftVariantId") {
      if (!isProductionBuild) {
        console.error(response.deleteShiftVariant.message);
      }
      throw new ApiError(response.deleteShiftVariant.message);
    }
  }

  /**
   * Get user preferences.
   */
  async function getUserPreferences(
    config?: RequestConfig
  ): Promise<UserPreferences> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetUserPreferencesQuery,
        IGetUserPreferencesQueryVariables
      >({
        document: GetUserPreferencesDocument,
        signal: config?.signal,
      })
    );

    if (response.userPreferences.__typename !== "UserPreferences") {
      if (!isProductionBuild) {
        console.error(response.userPreferences.message);
      }
      throw new ApiError(response.userPreferences.message);
    }

    const userPreferences: UserPreferences = {
      onboardingGuides: response.userPreferences.onboardingGuides,
    };
    return userPreferencesSchema.parse(userPreferences);
  }

  /**
   * Save user preferences.
   */
  async function saveUserPreferences(
    params: UserPreferences,
    config?: RequestConfig
  ): Promise<UserPreferences> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        ISaveUserPreferencesMutation,
        ISaveUserPreferencesMutationVariables
      >({
        document: SaveUserPreferencesDocument,
        variables: params,
        signal: config?.signal,
      })
    );

    if (response.saveUserPreferences.__typename !== "UserPreferences") {
      if (!isProductionBuild) {
        console.error(response.saveUserPreferences.message);
      }
      throw new ApiError(response.saveUserPreferences.message);
    }

    const userPreferences: UserPreferences = {
      onboardingGuides: response.saveUserPreferences.onboardingGuides,
    };
    return userPreferencesSchema.parse(userPreferences);
  }

  /**
   * Get notification rules for the settings screen.
   */
  async function getNotificationRules(
    params: NotificationRulesFilters,
    config?: RequestConfig
  ): Promise<Array<NotificationRule>> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetNotificationRulesQuery,
        IGetNotificationRulesQueryVariables
      >({
        document: GetNotificationRulesDocument,
        variables: params,
        signal: config?.signal,
      })
    );

    try {
      return response.notificationRules.map(parseNotificationRuleDto);
    } catch (_error) {
      throw new ResponseParsingError();
    }
  }

  /**
   * Create notification rule.
   */
  async function createNotificationRule(
    params: Omit<NotificationRule, "id">,
    config?: RequestConfig
  ): Promise<NotificationRule> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        ICreateNotificationRuleMutation,
        ICreateNotificationRuleMutationVariables
      >({
        document: CreateNotificationRuleDocument,
        variables: {
          data: {
            ...mapNotificationRuleToDto(params),
            ruleType: mapNotificationRuleKeyToKpiMetric(params.key),
          },
        },
        signal: config?.signal,
      })
    );

    if (response.createNotificationRule.__typename !== "NotificationRule") {
      if (!isProductionBuild) {
        console.error(response.createNotificationRule.message);
      }
      throw new ApiError(response.createNotificationRule.message);
    }

    return parseNotificationRuleDto(response.createNotificationRule);
  }

  /**
   * Update notification rule.
   */
  async function updateNotificationRule(
    params: NotificationRule,
    config?: RequestConfig
  ): Promise<NotificationRule> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IUpdateNotificationRuleMutation,
        IUpdateNotificationRuleMutationVariables
      >({
        document: UpdateNotificationRuleDocument,
        variables: {
          data: {
            ...mapNotificationRuleToDto(params),
            id: params.id,
          },
        },
        signal: config?.signal,
      })
    );

    if (response.updateNotificationRule.__typename !== "NotificationRule") {
      if (!isProductionBuild) {
        console.error(response.updateNotificationRule.message);
      }
      throw new ApiError(response.updateNotificationRule.message);
    }

    return parseNotificationRuleDto(response.updateNotificationRule);
  }

  /**
   * Delete notification rule.
   */
  async function deleteNotificationRule(
    params: NotificationRule,
    config?: RequestConfig
  ): Promise<NotificationRuleId> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IDeleteNotificationRuleMutation,
        IDeleteNotificationRuleMutationVariables
      >({
        document: DeleteNotificationRuleDocument,
        variables: {
          data: { id: params.id },
        },
        signal: config?.signal,
      })
    );

    if (
      response.deleteNotificationRule.__typename !== "DeletedNotificationRuleId"
    ) {
      if (!isProductionBuild) {
        console.error(response.deleteNotificationRule.message);
      }
      throw new ApiError(response.deleteNotificationRule.message);
    }

    return response.deleteNotificationRule.id as NotificationRuleId;
  }

  async function getAreaOfInterestsStatistics(
    params: GetAreaOfInterestStatisticsFilters,
    config?: RequestConfig
  ) {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetAreaOfInterestsStatisticsQuery,
        IGetAreaOfInterestsStatisticsQueryVariables
      >({
        document: GetAreaOfInterestsStatisticsDocument,
        variables: {
          ...parseDateRangeParams(params),
          boxId: params.areaId,
          shiftGroupIds: params.shiftGroupIds,
        },
        signal: config?.signal,
      })
    );

    return areaOfInterestsStatisticsSchema.parse(
      response.areaOfInterestsStatistics
    );
  }

  async function getHeatmapByCameraId(
    params: GetHeatmapByCameraIdFilters,
    config?: RequestConfig
  ): Promise<Heatmap> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetHeatmapByCameraIdQuery,
        IGetHeatmapByCameraIdQueryVariables
      >({
        document: GetHeatmapByCameraIdDocument,
        variables: {
          cameraId: params.cameraId,
          shiftGroupIds: params.shiftsIds,
          dateRange: [
            formatDate(params.dateRange.values.start),
            formatDate(params.dateRange.values.end),
          ],
        },
        signal: config?.signal,
      })
    );

    const flattenedData = response.heatmap.flatMap(
      (heatmap) => heatmap.heatmap
    );

    return heatmapSchema.parse(flattenedData);
  }

  /**
   * Get virtual sensor image by line id.
   */
  async function getVirtualSensorImageByLineId(
    params: GetVirtualSensorImageByLineIdFilters,
    config?: RequestConfig
  ): Promise<VirtualSensorImage> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetVirtualSensorImageByLineIdQuery,
        IGetVirtualSensorImageByLineIdQueryVariables
      >({
        document: GetVirtualSensorImageByLineIdDocument,
        variables: {
          lineId: params.lineId,
        },
        signal: config?.signal,
      })
    );

    const camera = response.cameras.at(0)!;

    return {
      id: camera.id as CameraId,
      bgUrl: camera.latestSnapshot.presignedUrl,
      dimensions: {
        width: camera.resWidth,
        height: camera.resHeight,
      },
    };
  }

  /**
   * Get virtual sensor areas layout by line id.
   */
  async function getVirtualSensorAreasLayoutByLineId(
    params: GetVirtualSensorAreasLayoutByLineIdFilters,
    config?: RequestConfig
  ): Promise<VirtualSensorAreasLayout> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetVirtualSensorAreasLayoutByLineIdQuery,
        IGetVirtualSensorAreasLayoutByLineIdQueryVariables
      >({
        document: GetVirtualSensorAreasLayoutByLineIdDocument,
        variables: {
          lineId: params.lineId,
        },
        signal: config?.signal,
      })
    );

    const camera = response.cameras.at(0);

    if (!camera) {
      throw new NotFoundError();
    }

    const dimensions = {
      width: camera.resWidth,
      height: camera.resHeight,
    };

    const areas: VirtualSensorAreasLayout["areas"] = [];
    const areaCoordinatesByAreaId: AreaCoordinatesByAreaId = {};

    for (const area of camera.areas) {
      const downstreamArea = area.downstreamAreas.at(0);
      const areaId = area.id as AreaId;
      const coordinates = convertRelativeToAbsoluteCoordinates(
        relAreaCoordinates.parse({
          x: area.coordinates.x,
          y: area.coordinates.y,
          width: area.coordinates.width,
          height: area.coordinates.height,
        }),
        dimensions
      );

      areaCoordinatesByAreaId[areaId] = coordinates;

      areas.push({
        id: areaId,
        name: area.group.name,
        color: area.group.color,
        coordinates,
        stationId:
          downstreamArea &&
          downstreamArea.cameraStation &&
          // if station is deactivated we don't want to relate it to an area
          // but we still want to see some area only statistics
          !downstreamArea.cameraStation.station.deactivated
            ? (downstreamArea.cameraStation.station.id as StationId)
            : null,
      });
    }

    const virtualSensor: VirtualSensorAreasLayout = {
      id: camera.id as CameraId,
      dimensions,
      areas,
      areaCoordinatesByAreaId,
    };

    return virtualSensorAreasLayoutSchema.parse(virtualSensor);
  }

  async function getAreaConnections(
    params: GetAreaConnectionsFilters,
    config?: RequestConfig
  ): Promise<AreaConnections> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetAreaConnectionsQuery,
        IGetAreaConnectionsQueryVariables
      >({
        document: GetAreaConnectionsDocument,
        variables: {
          ...parseDateRangeParams(params),
          fromArea: params.areaId,
          shiftGroupIds: params.shiftGroupIds,
        },
        signal: config?.signal,
      })
    );

    const connections = response.areaConnections.connections.map((it) => ({
      areaId: it.cameraBoundingBox.id as AreaId,
      count: it.count,
    }));

    return areaConnectionsSchema.parse(connections);
  }

  async function getStationCyclesAndEvents(
    params: StationCyclesAndEventsFilters,
    config?: RequestConfig
  ): Promise<StationCyclesAndEvents> {
    const response = await catchRequestErrors(() =>
      gqlClient.request<
        IGetStationCyclesAndEventsQuery,
        IGetStationCyclesAndEventsQueryVariables
      >({
        document: GetStationCyclesAndEventsDocument,
        variables: {
          timestampStart: format(params.timestampStart, "yyyy-MM-dd HH:mm"),
          timestampEnd: format(params.timestampEnd, "yyyy-MM-dd HH:mm"),
          stationId: params.stationId,
        },
        signal: config?.signal,
      })
    );

    if (response.getStationCyclesAndEventsData == null) {
      throw new NotFoundError();
    }

    if (
      response.getStationCyclesAndEventsData.__typename !==
      "StationCyclesAndEventsDTO"
    ) {
      if (!isProductionBuild) {
        console.error(response.getStationCyclesAndEventsData.message);
      }
      throw new ApiError(response.getStationCyclesAndEventsData.message);
    }

    const results: StationCyclesAndEvents = {
      activities: response.getStationCyclesAndEventsData.activities.map(
        (activity) => ({
          ...activity,
          productId: tagIdSchema.parse(activity.tagId),
          data: replaceNegativeValuesWithZero(activity.data, params.stationId),
        })
      ),
      cycles: response.getStationCyclesAndEventsData.cycles.map((cycle) => ({
        cycleId: cycle.cycleId,
        variantId: cycle.variantId,
        timestampStart: parseISO(cycle.timestampStart),
        timestampEnd: parseISO(cycle.timestampEnd),
        type: cycleTypeSchema.parse(cycle.type),
      })),
      events: response.getStationCyclesAndEventsData.events.map((event) => ({
        id: event.id,
        timestamp: parseISO(event.timestamp),
        type: eventTypeSchema.parse(event.type),
        metadata: event.metadata,
        areaId: event.areaId,
        stationId: event.stationId,
        cameraId: event.cameraId,
      })),
      timeWindows: response.getStationCyclesAndEventsData.timeWindows.map(
        (timeWindow) => ({
          variantId: timeWindow.variantId,
          type: timeWindowTypeSchema.parse(timeWindow.type),
          startTime: parseISO(timeWindow.startTime),
          endTime: parseISO(timeWindow.endTime),
        })
      ),
    };

    return stationCyclesAndEventsSchema.parse(results);
  }

  return Object.freeze({
    getLinesWithMetrics,
    getStationsWithMetrics,
    getOperationalLevels,
    getShiftsWithVariants,
    getVideosForStations,
    getAllTags,
    getNotifications,
    getNotificationById,
    updateNotification,
    getStationStatistics,
    updateVideoDescription,
    createVideoBookmark,
    deleteVideoBookmark,
    createTag,
    tagVideo,
    untagVideo,
    createShiftVariant,
    updateShiftVariant,
    createShiftVariantOverride,
    deleteShiftVariant,
    getUserPreferences,
    saveUserPreferences,
    getNotificationRules,
    createNotificationRule,
    updateNotificationRule,
    deleteNotificationRule,
    getAreaOfInterestsStatistics,
    getHeatmapByCameraId,
    getVirtualSensorImageByLineId,
    getVirtualSensorAreasLayoutByLineId,
    getAreaConnections,
    getLineStatisticsAccumulatedByTime,
    getLineAverageCycleTimeByStation,
    getLineOutputPerStationStatistics,
    getLineActivityPerStationStatistics,
    getLineOverallStatistics,
    getStationCyclesAndEvents,
  });
}

/**
 * Parses the KPI type returned from the API to the domian type.
 * Needed for type safety at build time.
 */
function parseKpiType(type: IKpiType): KpiType {
  switch (type) {
    case IKpiType.Activity:
      return "activity";
    case IKpiType.CycleCount:
      return "cycle_count";
    case IKpiType.CycleTime:
      return "cycle_time";
    case IKpiType.Output:
      return "output";
    case IKpiType.Tact:
      return "tact";
    case IKpiType.Workers:
      return "workers";
    default:
      throw new ResponseParsingError();
  }
}

/**
 * Parses the KPI value type returned from the API to the domian type.
 * Needed for type safety at build time.
 */
function parseKpiValueType(type: IKpiValueType): KpiValueType {
  switch (type) {
    case IKpiValueType.ActivityActive:
      return "active";
    case IKpiValueType.ActivityEmpty:
      return "empty";
    case IKpiValueType.ActivityIdle:
      return "idle";
    case IKpiValueType.ActivityInterrupted:
      return "interrupted";
    case IKpiValueType.Actual:
      return "actual";
    case IKpiValueType.Differential:
      return "differential";
    default:
      throw new ResponseParsingError();
  }
}

/**
 * Parses KPI metrics returned from the API to the domian type.
 * Needed for type safety at build time.
 */
function parseMetrics(metrics: Array<IKpiMetric>): Array<KpiMetric> {
  return metrics
    .filter(
      // filter out productivity
      // TODO: fix on the backend side
      (metric) => IKpiType.Productivity !== metric.type
    )
    .map((metric) => {
      const type = parseKpiType(metric.type);
      return {
        type,
        average: normalizeKpiValue(type, metric.average),
        values: metric.values.map((value) => ({
          type: parseKpiValueType(value.type),
          value: normalizeKpiValue(type, Math.max(0, value.value)),
          overTime: value.overTime.map((d) => ({
            date: parseISO(d.date),
            value: normalizeKpiValue(type, Math.max(0, d.value)),
          })),
        })),
      };
    });
}

/**
 * Normalize KPI value based on KPI type.
 */
function normalizeKpiValue(metricType: KpiType, value: number) {
  const rounded10 = Math.round(value * 10) / 10;
  switch (metricType) {
    case "activity":
      return Math.round(value * 10_000) / 100;
    case "tact":
    case "cycle_time":
      return Math.round(value);
    case "workers":
      return rounded10 ? rounded10 : value;
    case "cycle_count":
    case "output":
    default:
      return Math.ceil(value);
  }
}

function parseLineData(
  responseData: IGetLinesWithMetricsQuery["linesWithMetrics"],
  tracingClient: TracingClient
): { data: Array<Array<LineWithMetrics>>; timeGranularity: TimeGranularity } {
  if (responseData.__typename === "MissingRequiredFields") {
    throw new BadRequestError();
  }
  if (responseData.data.length === 0) {
    throw new NotFoundError();
  }

  try {
    return {
      timeGranularity: parseTimeGranularity(responseData.timeGranularity),
      data: [
        responseData.data.map((line) => {
          // manual parsing needed for type safety taking advantage of types generated from graphql schema.
          // passing this only through ZOD would make it type safe during runtime, but not during compile time.
          const domainLine: LineWithMetrics = {
            id: line.id as LineId,
            factoryId: line.factoryId as FactoryId,
            name: line.name,
            metrics: parseMetrics(line.metrics),
          };
          // additional validation from ZOD schema
          return lineWithMetricsSchema.parse(domainLine);
        }),
      ],
    };
  } catch (error) {
    if (!isProductionBuild) {
      console.error(error);
    }
    tracingClient.captureMessage(`Error parsing line data: ${error}`);
    throw new ResponseParsingError();
  }
}

function parseStationData(
  responseData: IGetStationsWithMetricsQuery["stationsWithMetrics"],
  tracingClient: TracingClient
): {
  timeGranularity: TimeGranularity;
  data: Array<Array<StationWithMetrics>>;
} {
  if (responseData.__typename === "MissingRequiredFields") {
    throw new BadRequestError();
  }
  if (responseData.data.length === 0) {
    throw new NotFoundError();
  }

  try {
    return {
      timeGranularity: parseTimeGranularity(responseData.timeGranularity),
      data: [
        responseData.data.map((station) => {
          // manual parsing needed for type safety taking advantage of types generated from graphql schema.
          // passing this only through ZOD would make it type safe during runtime, but not during compile time.
          const domainStation: StationWithMetrics = {
            id: station.id as StationId,
            factoryId: station.factoryId as FactoryId,
            lineId: station.lineId as LineId,
            name: station.name,
            metrics: parseMetrics(station.metrics),
          };
          // additional validation from ZOD schema
          return stationWithMetricsSchema.parse(domainStation);
        }),
      ],
    };
  } catch (error) {
    if (!isProductionBuild) {
      console.error(error);
    }
    tracingClient.captureMessage(`Error parsing station data: ${error}`);
    throw new ResponseParsingError();
  }
}

/**
 * Formats date with a format yyyy-MM-dd
 */
function formatDate(date: Date) {
  return format(date, "yyyy-MM-dd");
}

/**
 * Formats date with a format HH:mm:ss
 */
function formatTime(date: Date) {
  return format(date, "HH:mm:ss");
}

/**
 * Parses time filter parameters to query parameters for the "PLUS" queries.
 */
function parseDateRangeParams(params: {
  dateRange: DateRangeFilter;
  timeRange?: TimeRangeFilter;
}) {
  return {
    exludedWeekdays: params.dateRange.excluded,
    dateRanges: [
      formatDate(params.dateRange.values.start),
      formatDate(params.dateRange.values.end),
    ],
    timeRanges: (params.timeRange?.values ?? []).map((timeRange) => [
      formatTime(timeRange.start),
      formatTime(timeRange.end),
    ]),
  };
}

/**
 * Parses the given time granularity and returns the corresponding string representation.
 * @param granularity - The time granularity to parse.
 * @returns The string representation of the time granularity.
 * @throws {ResponseParsingError} If the time granularity is not recognized.
 */
function parseTimeGranularity(granularity: ITimeGranularity): TimeGranularity {
  switch (granularity) {
    case ITimeGranularity.Hour:
      return "hour";
    case ITimeGranularity.Day:
      return "day";
    case ITimeGranularity.Week:
      return "week";
    case ITimeGranularity.Month:
      return "month";
    case ITimeGranularity.Quarter:
      return "quarter";
    default:
      throw new ResponseParsingError();
  }
}

function parseTagType(type: ITag["tagType"]): TagType {
  switch (type) {
    case 0:
      return "System";
    case 1:
      return "User";
    case 2:
      return "CV";
    case 3:
      return "Product";
    default:
      throw new ResponseParsingError();
  }
}

function parseShiftsData(
  shiftsDto: IGetFactoriesWithLinesAndStationsQuery["organizationalLevels"][number]["lines"][number]["shifts"]
): Array<Shift> {
  return shiftsDto.map((shift) => ({
    id: shift.id as ShiftId,
    name: shift.name,
    start: parse(shift.start, "HH:mm:ss", new Date()),
    end: parse(shift.end, "HH:mm:ss", new Date()),
  }));
}

function parseShiftVariant(
  variant: Omit<IShiftVariant, "shiftGroup">,
  shiftId: ShiftId
): ShiftVariant {
  const times = variant.workingTimes
    .flat()
    .map((t) => parse(t, "HH:mm:ss", new Date()));
  const startTime = times.shift() ?? new Date();
  const endTime = times.pop() ?? new Date();
  const shiftVariant: ShiftVariant = {
    id: variant.id as ShiftVariantId,
    shiftId,
    name: variant.name,
    enabled: !variant.disabled,
    repeatStart: parseISO(variant.repeatStart),
    repeatEvery: variant.repeatEvery,
    repeatInterval: variant.repeatInterval as "week" | "month",
    start: startTime,
    end: endTime,
    breaks: unflattenArray(times).map((t) => ({ start: t[0], end: t[1] })),
    weekdaysWithTargets: variant.weekdaysTargets,
  };
  return shiftVariantSchema.parse(shiftVariant);
}

function parseNptificationRuleType(
  type: IKpiMetricType
): NotificationRule["type"] {
  switch (type) {
    case IKpiMetricType.Output:
      return "output";
    case IKpiMetricType.Activity:
      return "activity";
    case IKpiMetricType.AvgCycleTime:
      return "avg_cycle_time";
    case IKpiMetricType.TotalCycleTime:
      return "total_cycle_time";
    case IKpiMetricType.Persons:
      return "persons";
    default:
      throw new ResponseParsingError();
  }
}

function mapNotificationRuleTypeToKpiMetric(
  type: NotificationRule["type"]
): IKpiMetricType {
  switch (type) {
    case "output":
      return IKpiMetricType.Output;
    case "activity":
      return IKpiMetricType.Activity;
    case "avg_cycle_time":
      return IKpiMetricType.AvgCycleTime;
    case "total_cycle_time":
      return IKpiMetricType.TotalCycleTime;
    case "persons":
      return IKpiMetricType.Persons;
    default:
      throw new BadRequestError();
  }
}

function parseNptificationRuleRelation(
  type: IRelationType
): NotificationRule["relation"] {
  switch (type) {
    case IRelationType.AboveThan:
      return "gte";
    case IRelationType.BelowThan:
      return "lte";
    case IRelationType.Equals:
      return "eq";
    default:
      throw new ResponseParsingError();
  }
}

function mapNotificationRuleRelationToRelationType(
  type: NotificationRule["relation"]
): IRelationType {
  switch (type) {
    case "gte":
      return IRelationType.AboveThan;
    case "lte":
      return IRelationType.BelowThan;
    case "eq":
      return IRelationType.Equals;
    default:
      throw new BadRequestError();
  }
}

function parseNptificationRuleDelivery(
  type: IMediumType
): NotificationRule["delivery"] {
  switch (type) {
    case IMediumType.Email:
      return "email";
    case IMediumType.InApp:
      return "in_app";
    case IMediumType.None:
      return "none";
    default:
      throw new ResponseParsingError();
  }
}

function mapNotificationRuleDeliveryToMediumType(
  type: NotificationRule["delivery"]
): IMediumType {
  switch (type) {
    case "email":
      return IMediumType.Email;
    case "in_app":
      return IMediumType.InApp;
    case "none":
      return IMediumType.None;
    default:
      throw new BadRequestError();
  }
}

function parseNptificationRuleThresholdType(
  type: IThresholdType
): NotificationRule["context"]["thresholdType"] {
  switch (type) {
    case IThresholdType.Absolute:
      return "absolute";
    case IThresholdType.PercentToTarget:
      return "percent_of_target";
    default:
      throw new ResponseParsingError();
  }
}

function mapNotificationRuleThresholdToThresholdType(
  type: NotificationRule["context"]["thresholdType"]
): IThresholdType {
  switch (type) {
    case "absolute":
      return IThresholdType.Absolute;
    case "percent_of_target":
      return IThresholdType.PercentToTarget;
    default:
      throw new BadRequestError();
  }
}

function parseNotificationRuleKey(type: IRuleType): NotificationRule["key"] {
  switch (type) {
    case IRuleType.Custom:
      return "custom";
    case IRuleType.NoLineOutput:
      return "no_line_output";
    case IRuleType.OutputTargetMet:
      return "target_output_achieved";
    case IRuleType.OutputTargetNotMet:
      return "unachievable_target_output";
    default:
      throw new ResponseParsingError();
  }
}

function mapNotificationRuleKeyToKpiMetric(
  type: NotificationRule["key"]
): IRuleType {
  switch (type) {
    case "custom":
      return IRuleType.Custom;
    case "no_line_output":
      return IRuleType.NoLineOutput;
    case "target_output_achieved":
      return IRuleType.OutputTargetMet;
    case "unachievable_target_output":
      return IRuleType.OutputTargetNotMet;
    default:
      throw new BadRequestError();
  }
}

function parseNotificationRuleDto(
  ruleDto: IGetNotificationRulesQuery["notificationRules"][number]
): NotificationRule {
  const rule: NotificationRule = {
    id: ruleDto.id as NotificationRuleId,
    title: ruleDto.title,
    key: parseNotificationRuleKey(ruleDto.ruleType),
    type: parseNptificationRuleType(ruleDto.kpiMetric),
    stationId: ruleDto.station.id as StationId,
    relation: parseNptificationRuleRelation(ruleDto.relation),
    delivery: parseNptificationRuleDelivery(ruleDto.medium),
    context: {
      periodSeconds: ruleDto.context.periodSeconds,
      thresholdType: parseNptificationRuleThresholdType(
        ruleDto.context.thresholdType
      ),
      thresholdValue: ruleDto.context.thresholdValue,
    },
  };
  return notificationRuleSchema.parse(rule);
}

function mapNotificationRuleToDto(params: Omit<NotificationRule, "id">) {
  return {
    title: params.title,
    station: { id: params.stationId },
    medium: mapNotificationRuleDeliveryToMediumType(params.delivery),
    kpiMetric: mapNotificationRuleTypeToKpiMetric(params.type),
    relation: mapNotificationRuleRelationToRelationType(params.relation),
    context: {
      thresholdType: mapNotificationRuleThresholdToThresholdType(
        params.context.thresholdType
      ),
      thresholdValue: params.context.thresholdValue,
      periodSeconds: params.context.periodSeconds,
    },
  };
}

function parseISO(dateStr: string) {
  // we get rid of the timezone information because we want to show the date
  // in the timezone of the factory location and it's hard to do using
  // the current JS Date API. We should consider using a library like
  // `date-fns-tz` to handle this or the new Intl.DateTimeFormat API
  const noTZDateStr = dateStr.replace(/\+\d{2}:\d{2}|-\d{2}:\d{2}|Z$/, "");
  return parseIsoFns(noTZDateStr);
}

/**
 * Replace negative values with zero.
 *
 * This is a temporary solution until we find the root cause of the negative values.
 */
function replaceNegativeValuesWithZero(
  data: Array<{ key: string; value: number }>,
  stationId: string
): Array<{ key: string; value: number }> {
  return data.map((it) => {
    if (it.value < 0) {
      if (!isProductionBuild) {
        console.error(
          `Negative value for station ${stationId} => [key ${it.key}]: ${it.value}`
        );
      }
      it.value = 0;
    }
    return it;
  });
}

function parseStationType(type: IStationTypes): StationType {
  switch (type) {
    case IStationTypes.Machine:
      return "machine";
    default:
      return "manual";
  }
}
