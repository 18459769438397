import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLingui } from "@lingui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchAuthSession } from "aws-amplify/auth";
import { LogOutIcon } from "lucide-react";

import {
  Avatar,
  AvatarFallback,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/view/components";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { cn } from "@/view/utils";

import { LanguageSwitcher } from "./language-switcher";

export function ProfileNav() {
  const { i18n } = useLingui();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { signOut, user } = useAuthenticator();

  const sessionQuery = useQuery({
    queryKey: ["authSession", user?.userId ?? ""],
    queryFn: () => fetchAuthSession(),
  });

  const email =
    (sessionQuery.data?.tokens?.idToken?.payload.email as string) ??
    user?.signInDetails?.loginId ??
    "";

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="rounded-full focus:outline-none"
        onClick={() => analytics.collect("profile_nav_clicked")}
      >
        <Avatar className="w-10 h-10">
          <AvatarFallback>{parseInitials(email)}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mx-4 min-w-[240px]">
        <div className="px-2 py-2 gap-2 flex items-center">
          <Avatar className="w-12 h-12">
            <AvatarFallback>{parseInitials(email)}</AvatarFallback>
          </Avatar>
          <p
            className={cn(
              "text-sm text-brand-gray-5",
              "text-brand-gray-4 max-w-[10rem]",
              "text-ellipsis overflow-hidden whitespace-nowrap"
            )}
          >
            {email || i18n.t("profileNavEmail")}
          </p>
        </div>
        <DropdownMenuSeparator />
        <div className="p-2">
          <LanguageSwitcher />
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className={cn(
            "text-brand-warning cursor-pointer",
            "hover:bg-brand-gray-1"
          )}
          onClick={() => {
            signOut();
            analytics.resetUser();
            queryClient.clear(); // clear all cached queries
          }}
        >
          <LogOutIcon className="h-4 w-4" />
          {i18n.t("profileNavLabelLogout")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function parseInitials(name: string) {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();
}
