import { useLingui } from "@lingui/react";
import { Clock2Icon } from "lucide-react";
import { useState } from "react";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/view/components";
import { DurationInput } from "@/view/components/form/duration-input";
import { cn, padNumberWithZero, secondsToDuration } from "@/view/utils";

import {
  DurationRange,
  useDurationRange,
} from "../line-id-video-library/use-duration-range";

const defaultDurationRange = [0, 60 * 60] as [number, number];

export function DurationRangeSelect({
  onChange,
}: {
  onChange: (value: DurationRange) => void;
}) {
  const { i18n } = useLingui();
  const durationRange = useDurationRange();
  const [range, setRange] = useState(durationRange ?? defaultDurationRange);

  return (
    <div className="flex gap-1">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            analyticsEvent="analyze_info_icon_clicked"
            className={cn(
              "border-2 rounded-lg border-brand-gray-2",
              "bg-brand-white hover:bg-brand-gray-2",
              "font-semibold text-brand-gray-5"
            )}
          >
            <span className="select-none flex gap-2 justify-between items-center">
              <Clock2Icon />
              {formatDurationRange(durationRange)}
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="min-w-[240px] rounded-lg">
          <div className="flex gap-2 items-center px-4 py-2">
            <span className="text-sm">{i18n.t("From")}</span>
            <DurationInput
              className={cn(
                "border-2 border-brand-gray-2 rounded-lg",
                "bg-brand-white font-semibold text-brand-gray-5"
              )}
              value={range[0]}
              onChange={(value) => {
                if (value < 0) {
                  value = 0;
                } else if (value > 3600) {
                  value = 3600;
                }
                setRange([value, value > range[1] ? value + 60 : range[1]]);
              }}
            />
            <span className="text-sm">{i18n.t("To")}</span>
            <DurationInput
              className={cn(
                "border-2 border-brand-gray-2 rounded-lg",
                "bg-brand-white font-semibold text-brand-gray-5"
              )}
              value={range[1]}
              onChange={(value) => {
                if (value < 0) {
                  value = 0;
                } else if (value > 3600) {
                  value = 3600;
                }
                setRange([
                  value <= range[0] ? Math.max(value - 60, 0) : range[0],
                  value,
                ]);
              }}
            />
            <Button
              analyticsEvent="duration_range_changed"
              className={cn(
                "rounded-md px-3",
                "text-brand-white font-semibold transition-colors",
                "bg-brand-blue-1 hover:bg-brand-blue-2"
              )}
              onClick={() => onChange(range)}
            >
              {i18n.t("Apply")}
            </Button>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
      <Button
        analyticsEvent="duration_range_changed"
        className={cn(
          "border-2 rounded-lg border-brand-gray-2",
          "bg-brand-white hover:bg-brand-gray-2",
          "font-semibold text-brand-gray-5"
        )}
        onClick={() => onChange(defaultDurationRange)}
      >
        {i18n.t("Reset filter")}
      </Button>
    </div>
  );
}

function formatDurationInSeconds(durationInSeconds: number) {
  return secondsToDuration(durationInSeconds).map(padNumberWithZero).join(":");
}

function formatDurationRange(range: DurationRange) {
  const [start, end] = range ?? [0, 60 * 60];
  return `${formatDurationInSeconds(start)}-${formatDurationInSeconds(end)}`;
}
