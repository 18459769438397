import { z } from "zod";

import { entitySchema, entityWithMetricsSchema } from "./common/entity";
import { factoryIdSchema } from "./factory";
import { lineIdSchema } from "./line";

const stationTypeSchema = z.enum(["manual", "machine"]);

export type StationType = z.infer<typeof stationTypeSchema>;
export const stationIdSchema = z.string().brand<"StationId">();
export const stationSchema = entitySchema.extend({
  id: stationIdSchema,
  factoryId: factoryIdSchema,
  lineId: lineIdSchema,
  isOutput: z.boolean(),
  type: stationTypeSchema,
});
export const stationWithMetricsSchema = entityWithMetricsSchema.extend({
  id: stationIdSchema,
  factoryId: factoryIdSchema,
  lineId: lineIdSchema,
});

export type StationId = z.infer<typeof stationIdSchema>;
export type Station = z.infer<typeof stationSchema>;
export type StationWithMetrics = z.infer<typeof stationWithMetricsSchema>;
