import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { useLineStatisticsParams } from "./line-statistics-provider";
import { LineWidgetActivityError } from "./line-widget-activity";
import { LineWidgetCycleTimeError } from "./line-widget-cycle-time";
import { LineWidgetOutputError } from "./line-widget-output";
import { LineWidgetLayout } from "./line-widgets-layout";

export function LineStatisticsWidgetsErrorBoundary({
  children,
}: PropsWithChildren) {
  const params = useLineStatisticsParams();
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          resetKeys={params}
          onReset={reset}
          fallbackRender={() => (
            <LineWidgetLayout>
              <LineWidgetOutputError />
              <LineWidgetCycleTimeError />
              <LineWidgetActivityError />
            </LineWidgetLayout>
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
