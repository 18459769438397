import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { StationId } from "@/domain/station";
import { LineActivityPerStationStatistics } from "@/domain/statistics";
import { ChartErrorStatus } from "@/view/components";
import { useSelectedStationIds } from "@/view/pages/line-id/use-selected-station-ids";
import { useApiClient } from "@/view/providers/api-client-provider";

import {
  ActivityByStationChart,
  ActivityByStationChartData,
  ActivityType,
} from "./charts/activity-by-station-chart";
import { LineReportingChartsLoading } from "./line-statistics";
import { useLineStatisticsParams } from "./line-statistics-provider";

function toActivityByStationChartData(stationIds: Array<StationId>) {
  return (
    data: LineActivityPerStationStatistics
  ): Array<ActivityByStationChartData> => {
    const result: Array<ActivityByStationChartData> = [];

    for (const stationId of stationIds) {
      const dataByStation = data[stationId];
      const stationData: ActivityByStationChartData = {
        stationId,
        products: {},
      };

      if (dataByStation) {
        for (const productData of dataByStation) {
          const productId = productData.productId;
          const values = productData.data;

          const aggregatedValues = values.reduce<
            Record<ActivityType | "total", number>
          >(
            (acc, it) => {
              acc[it.key as ActivityType | "total"] = it.value;
              acc.total += it.value;
              return acc;
            },
            { total: 0, person_product: 0, person: 0, product: 0, empty: 0 }
          );

          stationData.products[productId] = {
            person_product: getValuesForKey("person_product", aggregatedValues),
            person: getValuesForKey("person", aggregatedValues),
            product: getValuesForKey("product", aggregatedValues),
            empty: getValuesForKey("empty", aggregatedValues),
          };
        }
      }

      result.push(stationData);
    }

    return result;
  };
}

function getValuesForKey(
  key: ActivityType,
  values: Record<ActivityType | "total", number>
) {
  const value = values[key];
  const total = values.total;
  return {
    value: Math.round(value),
    relativeValue: total > 0 ? value / total : 0,
  };
}

export function LineActivityByStationChart({
  height,
  onStationSelect,
}: {
  height: number;
  onStationSelect: (stationId: StationId) => void;
}) {
  const apiClient = useApiClient();
  const [line, shiftIds, tagIds, dateRange, productIds] =
    useLineStatisticsParams();
  const stationIds = useSelectedStationIds();

  const toChartData = useMemo(
    () => toActivityByStationChartData(stationIds),
    [stationIds]
  );

  const query = useQuery({
    queryKey: [
      "line-activity-by-station-statistics",
      line.id,
      shiftIds,
      dateRange,
    ],
    queryFn: () =>
      apiClient.getLineActivityPerStationStatistics({
        lineId: line.id,
        shiftIds,
        dateRange,
        // TODO: we don't use them
        tagIds,
        productIds,
      }),

    select: toChartData,
  });

  if (query.isLoading) {
    return <LineReportingChartsLoading />;
  }

  if (query.isSuccess) {
    return (
      <ActivityByStationChart
        height={height}
        data={query.data}
        onBarClick={onStationSelect}
      />
    );
  }

  return <ChartErrorStatus size="medium" className="min-h-[540px]" />;
}
